.btn_gps {
  position: absolute;
  right: 10px;
  bottom: 186px;
  z-index: 1;
  cursor: pointer;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  border-radius: 2px;
  width: 40px;
  height: 40px;
  touch-action: none;
  img {
    width: 100%;
    height: auto;
  }
}
