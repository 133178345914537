@import "setting";

/**********　常時表示部分　**********/
.box_always {
  z-index: 1200;
  position: absolute;
  top: calc(20px + var(--headerHeight));
  left: 0;
  @include fx;
  @include ai-c;
  .btn_mapmenu {
    margin-right: 10px;
    position: relative;
    background-color: $col-ki;
    border-radius: 0 1000px 1000px 0;
    border: 4px solid $col-tx;
    border-left: none;
    height: 60px;
    width: 70px;
    cursor: pointer;
    transition: 0.4s;
    &:hover {
      transform: scale(1.05);
    }
    img {
      transition: 0.4s;
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: auto;
    }
    .open {
      opacity: 1;
    }
    .close {
      opacity: 0;
    }
    &.on {
      .open {
        opacity: 0;
        transform: rotate(180deg);
      }
      .close {
        opacity: 1;
        transform: rotate(180deg);
      }
    }
  }
}
.btn_often {
  @include fx;
  @include ai-c;
  padding: 5px;
  border-radius: 1000px;
  background-color: rgba(0, 0, 0, 0.5);
  > .btn {
    @include fx;
    @include fx-r;
    figure {
      cursor: pointer;
      margin-left: 5px;
      background-color: white;
      border-radius: 1000px;
      height: 30px;
      aspect-ratio: 1/1;
      transition: 0.3s;
      background-color: white;
      line-height: 1;
      @include as-s;
      img {
        height: 100%;
        width: auto;
      }
      &:hover {
        background-color: $col-ki !important;
      }
    }
    &:first-child {
      figure {
        margin-left: 0;
      }
    }
    &:last-child {
      .txbox {
        > div {
          margin-right: 0 !important;
        }
      }
    }
    .txbox {
      > div {
        margin-right: 0;
        transition: 0.2s;
        width: 100%;
        height: 100%;
        background-color: white;
        border-radius: 1000px;
        display: none;
        overflow: hidden;
        margin-right: 0;
        white-space: nowrap;
        font-size: 14px;
        padding: 0 0.75em;
      }
      input {
        border: none !important;
        padding: 0;
        font-family: zen-maru-gothic, sans-serif;
        font-weight: bold;
        font-size: 14px;
        width: 100%;
        &:focus-visible {
          outline: none !important;
        }
      }
    }

    &.on {
      figure {
        background-color: $col-ki;
      }
      .txbox {
        > div {
          animation: btn_often_txbox 0.2s ease-out 0s 1 forwards;
          @include fx;
          @include fx-c;
          @include jc-c;
          margin-left: 5px;
          margin-right: 1em;
        }
      }
    }
    &.off {
      figure {
        background-color: white;
      }
      .txbox {
        animation: btn_often_txbox2 0.2s ease-out 0s 1 forwards;
        > div {
          display: none;
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
  }
}

@keyframes btn_often_txbox {
  0% {
    width: 30px;
    display: block;
  }
  100% {
    width: 15em;
    display: block;
  }
}
@keyframes btn_often_txbox2 {
  0% {
    width: 15em;
    display: block;
  }
  99% {
    width: 30px;
    display: block;
  }
  100% {
    display: block;
  }
}

/**********　メニュー枠　**********/
.blank_mapmenu {
  z-index: 1100;
  transform: translateX(-100vw);
  opacity: 0;
  position: absolute;
  top: var(--headerHeight);
  left: 0;
  width: 100%;
  height: calc(100% - var(--headerHeight));
  @include fx;
  @include fx-c;
  @include jc-s;
  @include ai-s;
  margin-top: 48px;
  > .box {
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    max-height: 80%;
    max-width: 97.5vw;
    position: relative;
    @include fx;
    background-color: white;
    border-radius: 0 100px 100px 0;
    border: 4px solid black;
    border-left: none;
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.25);
    &::-webkit-scrollbar {
      display: none;
    }
    &::before {
      content: "";
      position: sticky;
      top: 0;
      left: 0;
      display: inline-block;
      height: 100%;
      width: 20px;
      background-color: $col-ki;
    }
  }
  &.on {
    animation: blank_mapmenu 0.2s ease-out 0s 1 forwards;
    // > .box {
    // }
    .cat {
      &:nth-child(1) {
        animation: blank_mapmenu_cat 0.4s ease-out 0.3s 1 forwards;
      }
      &:nth-child(2) {
        animation: blank_mapmenu_cat 0.4s ease-out 0.35s 1 forwards;
      }
      &:nth-child(3) {
        animation: blank_mapmenu_cat 0.4s ease-out 0.4s 1 forwards;
      }
      &:nth-child(4) {
        animation: blank_mapmenu_cat 0.4s ease-out 0.45s 1 forwards;
      }
      &:nth-child(5) {
        animation: blank_mapmenu_cat 0.4s ease-out 0.5s 1 forwards;
      }
      &:nth-child(6) {
        animation: blank_mapmenu_cat 0.4s ease-out 0.55s 1 forwards;
      }
      &:nth-child(7) {
        animation: blank_mapmenu_cat 0.4s ease-out 0.6s 1 forwards;
      }
      &:nth-child(8) {
        animation: blank_mapmenu_cat 0.4s ease-out 0.65s 1 forwards;
      }
      &:nth-child(9) {
        animation: blank_mapmenu_cat 0.4s ease-out 0.7s 1 forwards;
      }
    }
  }
  &.off {
    animation: blank_mapmenu2 0.2s ease-out 0s 1 forwards;
    // .box {
    // }
    .cat {
      opacity: 1 !important;
      transform: translateY(0px) !important;
    }
  }
  .dtl {
    padding: 50px 75px 50px 55px;
    height: fit-content;
  }
  .cat {
    @include fx;
    @include fx-r;
    opacity: 0;
    transform: translateY(10px);
    > .tit {
      width: 6em;
      span {
        line-height: 1;
        display: inline-block;
        font-size: 14px;
        font-weight: bold;
        margin-top: 40px;
      }
    }
    .btn {
      padding: 15px 0px;
      ul {
        @include fx;
        @include fx-r;
        @include fx-w;
        list-style: none;
        margin: 0;
        padding: 0;
        li {
          @include fx;
          @include fx-c;
          @include ai-c;
          width: 95px;
          cursor: pointer;
          aspect-ratio: 1 / 1;
          figure {
            position: relative;
            display: inline-block;
            &::before,
            &::after {
              content: "";
              position: absolute;
              display: block;
              height: 100%;
              width: 100%;
              top: 0;
              left: 0;
              background-position: center;
              background-repeat: no-repeat;
              background-size: 0% 0%;
            }
            &::before {
              z-index: 100;
              background-image: url("../../../../assets/icons/map_menu/bg_on.svg");
            }
            &::after {
              z-index: 110;
              background-image: url("../../../../assets/icons/map_menu/bg_hov.svg");
              mix-blend-mode: multiply;
            }
            img {
              position: relative;
              width: 50px;
              height: auto;
              z-index: 130;
              margin: 5px;
              transition: 0.4s;
            }
          }
          &:hover {
            figure {
              img {
                transform: scale(1.1);
              }
              &::after {
                animation: mapmenubtn 0.2s ease-out 0s 1 forwards;
              }
            }
          }
          &:active {
            figure {
              transform: translateY(2px);
            }
          }
          &.on {
            figure {
              &::before {
                animation: mapmenubtn 0.2s ease-out 0s 1 forwards;
              }
            }
          }
          &.off {
            figure {
              &::before {
                animation: mapmenubtn2 0.3s ease-out 0s 1 forwards;
                background-size: 100% 100%;
              }
            }
          }
          figcaption {
            @include fx;
            @include fx-c;
            @include jc-c;

            font-size: 13px;
            font-weight: bold;
            line-height: 1.25;
            margin-top: 3px;
            text-align: center;
          }
        }
      }
    }
  }
}
@keyframes blank_mapmenu {
  0% {
    transform: translateX(-100vw);
    opacity: 0;
  }
  1% {
    transform: translateX(-15vw);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes blank_mapmenu2 {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  99% {
    transform: translateX(-15vw);
    opacity: 0;
  }
  100% {
    transform: translateX(-100vw);
    opacity: 0;
  }
}
@keyframes blank_mapmenu_cat {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
@keyframes mapmenubtn {
  0% {
    background-size: 0% 0%;
  }
  100% {
    background-size: 100% 100%;
  }
}
@keyframes mapmenubtn2 {
  0% {
    background-size: 100% 100%;
  }
  100% {
    background-size: 0% 0%;
  }
}

/*---------------------------------------------------------------------------BP1920*/
@media (max-width: 1920px) {
}
/*---------------------------------------------------------------------------BP1600*/
@media (max-width: 1600px) {
}
/*---------------------------------------------------------------------------BP1280*/
@media (max-width: 1280px) {
}
/*---------------------------------------------------------------------------BP1024*/
@media (max-width: 1024px) {
}
/*---------------------------------------------------------------------------BP1023*/
@media (max-width: 1023px) {
  .box_always {
    top: var(--headerHeight);
    left: 15px;
    .btn_mapmenu {
      transform: rotate(90deg) scale(1);
      &:hover {
        transform: rotate(90deg) scale(1.1);
      }
      img {
        transform: rotate(-90deg);
        right: 3px;
      }
      &.on {
        + .btn_often {
          display: none;
        }
      }
    }
  }
  .btn_often {
    padding: 10px;
    margin-top: 5px;
    > .btn {
      figure {
        margin-left: 10px;
      }
    }
  }
  .blank_mapmenu {
    display: block;
    text-align: center;
    margin-top: 0;
    > .box {
      display: block;
      width: 90%;
      max-height: 95%;
      margin: auto;
      border-radius: 0 0 75px 75px;
      border: 4px solid black;
      border-top: none;
      padding: 0;
      &::before {
        width: 100%;
        height: 20px;
        position: sticky;
        z-index: 200;
        margin-top: -2px;
      }
    }
    .dtl {
      padding: 50px 7.5%;
    }
    .cat {
      @include fx-c;
      > .tit {
        width: 100%;
        text-align: left;
        border-bottom: 1px solid #ededed;
        line-height: 1;
        span {
          display: inline-block;
          line-height: 1;
          font-size: 12px;
          margin-top: 30px;
          padding: 0.5em 1.75em 0.35em 1em;
          background-color: #fcfdbd;
          border-radius: 4px 20px 0 0;
        }
      }
      .btn {
        padding: 10px 0 0;
        ul {
          li {
            width: calc((100% - (10px * 4)) / 5);
            border-radius: 10px;
            margin: 0 10px 10px 0;
            padding: 10px 0;
            background-color: #f8f8f8;
            aspect-ratio: unset;
            &:nth-child(5n) {
              margin-right: 0;
            }
            figcaption {
              height: 30px;
            }
          }
        }
      }
      &:first-child {
        .tit {
          span {
            margin-top: 0;
          }
        }
      }
    }
  }
  @keyframes blank_mapmenu {
    0% {
      transform: translate(0, -100vh);
      opacity: 0;
    }
    1% {
      transform: translate(0, -25vh);
      opacity: 0;
    }
    100% {
      transform: translate(0, 0vh);
      opacity: 1;
    }
  }
  @keyframes blank_mapmenu2 {
    0% {
      transform: translate(0, 0vh);
      opacity: 1;
    }
    99% {
      transform: translate(0, -25vh);
      opacity: 0;
    }
    100% {
      transform: translate(0, -100vh);
      opacity: 0;
    }
  }
}
/*---------------------------------------------------------------------------BP960*/
@media (max-width: 960px) {
}
/*---------------------------------------------------------------------------BP896*/
@media (max-width: 896px) {
}
/*---------------------------------------------------------------------------BP768*/
@media (max-width: 768px) {
}
/*---------------------------------------------------------------------------BP767*/
@media (max-width: 767px) {
}
/*---------------------------------------------------------------------------BP620*/
@media (max-width: 620px) {
  .btn_often {
    margin-left: -7px;
  }
  .box_always {
    left: 10px;
  }
  .blank_mapmenu > .box {
    border-radius: 0 0 50px 50px;
  }
  .blank_mapmenu .cat .btn ul li {
    width: calc((100% - (10px * 1)) / 2);
    aspect-ratio: unset;
    @include fx-r;
    @include jc-sb;
    padding: 0 10px 0 0;
    transition: 0.3s;
    overflow: hidden;
    &.on {
      figure {
        background-color: $col-ki;
      }
    }
    &:active {
      figure {
        transform: none;
      }
    }
    &:nth-child(5n) {
      margin-right: 10px;
    }
    &:nth-child(2n) {
      margin-right: 0px;
    }
    figure {
      margin-right: 5px;
      padding: 5px;
      width: 50px;
      img {
        width: 40px;
        margin: 0;
      }
      &::before,
      &::after {
        content: none;
      }
    }
    figcaption {
      margin-top: 0;
      text-align: left;
      width: calc(100% - 55px);
      br {
        display: none;
      }
    }
  }
}
/*---------------------------------------------------------------------------BP480*/
@media (max-width: 480px) {
}

@media (hover: none) {
  .blank_mapmenu .cat .btn ul li {
    &:hover {
      figure {
        img {
          transform: none;
        }
        &::after {
          animation: none;
        }
      }
    }
  }
}

.goButton {
  svg {
    font-size: 0.9rem;
    color: gray;
  }
}
