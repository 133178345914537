/* Scss Document */

////////////////////////////////*■■■SCSS形式で作成■■■*////////////////////////////////

@import "setting";
@import "../../../../assets/scss/dialogs.scss";

.caption_box_wrapper {
  position: relative;

  .caption_box1,
  .caption_box2 {
    display: inline-block;
    position: absolute;
    width: auto;
    box-sizing: border-box;
    word-break: keep-all;
    transition: left 0.2s, right 0.2s;
  }

  .caption_box1 {
    top: -45px;

    &.mobileOnly {
      top: -40px;
    }
  }

  .caption_box2 {
    top: -12px;
  }
}

.func_v2 {
  .ic_tit {
    img {
      margin-top: -3px;
    }
  }

  &.isMobileOnly {
    .ic_tit {
      img {
        margin-top: 10px !important;
      }
    }
  }

  &.isMobile {
    .ic_tit {
      img {
        margin-top: 4px !important;
      }
    }
  }

  &:first-of-type {
    margin-top: 0.5em;
  }
}

@media (max-width: 620px) {
  .caption_box_wrapper {
    .caption_box1 {
      top: -40px !important;
    }
  }

  .func_v2 {
    .ic_tit {
      width: 3em !important;
      img {
        margin-top: -2px !important;
        width: 2em !important;
        height: auto !important;
        margin-top: 3px !important;
      }
    }

    .setting {
      width: calc(100% - 3em) !important;
    }
  }
}

/***▼▼▼窓の中身『上段／コンテンツ部分』絞り込み用***/
.popup_map_filter {
  .ctt {
    @include pum_filter_ctt;
    &:last-child {
      @include pum_filter_ctt_last;
    }
  }
  .func {
    @include pum_filter_func;
    &:last-child {
      @include pum_filter_func_last;
    }
    /*
    .tit {@include pum_filter_func_tit;
      span {@include pum_filter_func_tit_span;}
    }
    */
    .dtl {
      @include pum_filter_func_dtl;
    }
  }
  .slider {
    @include pum_filter_slider;
    /*▼見た目だけ整えてます*/
    .dtl {
      @include pum_filter_slider_dtl;
    }
    .setting {
      @include pum_filter_slider_setting;
      .bar_bg {
        @include pum_filter_slider_setting_bar_bg;
      }
      .bar_range {
        @include pum_filter_slider_setting_bar_range;
      }
      .btn {
        @include pum_filter_slider_setting_btn;
        &.btn1 {
          @include pum_filter_slider_setting_btn_btn1;
        }
        &.btn2 {
          @include pum_filter_slider_setting_btn_btn2;
        }
      }
      .fukidashi {
        @include pum_filter_slider_setting_fukidashi;
        &::after {
          @include pum_filter_slider_setting_fukidashi_after;
        }
      }
    }
    /*▲見た目だけ整えてます*/
    .result {
      @include pum_filter_slider_result;
      &::before {
        @include pum_filter_slider_result_before;
      }
      .min,
      img,
      .max {
        @include pum_filter_slider_result_min_img_max;
      }
      .min,
      .max {
        @include pum_filter_slider_result_min_max;
        &::after {
          @include pum_filter_slider_result_min_max_after;
        }
      }
      .min {
        @include pum_filter_slider_result_min;
      }
      img {
        @include pum_filter_slider_result_img;
      }
      .max {
        @include pum_filter_slider_result_max;
      }
    }
    &.price .result {
      @include pum_filter_slider_price_result;
      &::before {
        @include pum_filter_slider_price_result_before;
      }
      .min,
      .max {
        @include pum_filter_slider_price_result_min_max;
        &::after {
          @include pum_filter_slider_price_result_min_max_after;
        }
      }
    }
    &.size .result {
      @include pum_filter_slider_size_result;
      &::before {
        @include pum_filter_slider_size_result_before;
      }
      .min,
      .max {
        @include pum_filter_slider_size_result_min_max;
        &::after {
          @include pum_filter_slider_size_result_min_max_after;
        }
      }
    }
    &.age .result {
      @include pum_filter_slider_age_result;
      &::before {
        @include pum_filter_slider_age_result_before;
      }
      .min,
      .max {
        @include pum_filter_slider_age_result_min_max;
        &::before {
          @include pum_filter_slider_age_result_min_max_before;
        }
        &::after {
          @include pum_filter_slider_age_result_min_max_after;
        }
      }
    }
  }
  .select {
    @include pum_filter_select; /*★★★*/
    .tit {
      @include pum_filter_select_tit; /*★★★*/
      span {
        @include pum_filter_select_tit_span; /*★★★*/
        &::before {
          @include pum_filter_select_tit_span_bef;
        } /*★★★*/
      } /*★★★*/
    } /*★★★*/
    .dtl {
      @include pum_filter_select_dtl; /*★★★*/
      .setting {
        @include pum_filter_select_dtl_setting;
      }
    }
  }
}

/*　▼▼▼改良版▼▼▼　*/
.popup_map_filter {
  .func_v2 {
    @include popup_map_filter_funcv2;
    > .box {
      @include popup_map_filter_funcv2_box;
    }
    .ic_tit {
      @include popup_map_filter_funcv2_ictit;
      img {
        @include popup_map_filter_funcv2_ictit_img;
      }
    }
    .setting {
      @include popup_map_filter_funcv2_setting;
    }
    .fkds_slider {
      @include popup_map_filter_funcv2_fkdsslider;
      .value {
        @include popup_map_filter_funcv2_fkdsslider_value;
      }
      small {
        @include popup_map_filter_funcv2_fkdsslider_small;
        &.off {
          @include popup_map_filter_funcv2_fkdsslider_small_off;
        }
      }
      .arrow {
        @include popup_map_filter_funcv2_fkdsslider_arrow;
      }
    }
    .fkds_slider_horn {
      @include popup_map_filter_funcv2_fkdssliderhorn;
      img {
        @include popup_map_filter_funcv2_fkdssliderhorn_img;
      }
    }
  }
}
/*　▲▲▲改良版▲▲▲　*/

/***▲▲▲窓の中身『絞り込み』***/

.backdrop {
  background-color: rgba(255, 255, 255, 0.75) !important;
}

.between {
  margin-top: 0.8em;
  font-weight: bold;
}

.dateButton {
  margin-right: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.clearButton {
  margin-left: 0 !important;
  padding: 0 0.5em !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;

  svg {
    margin-top: 0.4em;
    width: 0.8em;
    height: 0.8em;
  }
}
