.backgroundMap {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

@mixin chip {
  height: 1.6em !important;
  width: 6em;
  margin: -3px 0.5em 0 0.5em;
}

.infoChip {
  @include chip;
  background-color: lightgreen !important;
}

.warnChip {
  @include chip;
  background-color: orange !important;
}

.importantChip {
  @include chip;
  background-color: red !important;
  color: white !important;
}
