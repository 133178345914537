.licenseItem {
  text-align: left;
  width: 100%;
  padding: 2rem;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
}

.licenseText {
  margin-left: 8em;
  font-size: 10pt;
  overflow: scroll;
}
