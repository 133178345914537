.wrapper {
  width: 100%;

  .loading {
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
