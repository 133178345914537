.contentMain {
  width: 100vw;
  flex-grow: 1;
  padding-top: var(--headerHeight);
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
}

.message {
  width: 100%;

  .messageContent {
    position: absolute;
    width: 100%;
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
