/* Scss Document */

////////////////////////////////*■■■SCSS形式で作成■■■*////////////////////////////////

@import "setting";

.gm-style-iw-t:has(.moj-info) {
  .gm-style-iw-tc {
    display: none;
  }

  .gm-style-iw-chr {
    height: 1.5em;
  }

  button {
    border-radius: calc(100% / 2) !important;
    border: 2px solid black !important;
    background-color: white !important;
    opacity: 1 !important;
    top: -14px !important;
    right: -14px !important;

    transform: rotate(0deg) scale(1) !important;
    transition: transform 0.3s !important;

    span {
      margin: 10px !important;
    }

    &:hover {
      transform: rotate(180deg) scale(1.2) !important;
      transition: transform 0.3s !important;
    }
  }

  .gm-style-iw.gm-style-iw-c {
    overflow: visible;
    padding: 0 !important;
    border-width: 2px;
    border-color: black;
    border-style: solid;
    border-radius: 15px;

    .gm-style-iw-d {
      padding: 0;
      overflow: hidden !important;

      .moj-info {
        margin: 0;
        padding: 15px;
        font-family: "Lato", zen-maru-gothic, sans-serif;
        font-weight: 700;
        font-size: 110%;

        small {
          padding-left: 0.5em;
          font-size: 10px;
        }

        .map-name {
          margin-top: 20px;
          text-align: center;
          font-size: 65%;
          background-color: $col-ki;
        }
      }
    }
  }
}

/*---------------------------------------------------------------------------BP1920*/
@media (max-width: 1920px) {
}
/*---------------------------------------------------------------------------BP1600*/
@media (max-width: 1600px) {
}
/*---------------------------------------------------------------------------BP1280*/
@media (max-width: 1280px) {
}
/*---------------------------------------------------------------------------BP1024*/
@media (max-width: 1024px) {
}
/*---------------------------------------------------------------------------BP1023*/
@media (max-width: 1023px) {
}
/*---------------------------------------------------------------------------BP960*/
@media (max-width: 960px) {
}
/*---------------------------------------------------------------------------BP896*/
@media (max-width: 896px) {
}
/*---------------------------------------------------------------------------BP768*/
@media (max-width: 768px) {
}
/*---------------------------------------------------------------------------BP767*/
@media (max-width: 767px) {
}
/*---------------------------------------------------------------------------BP620*/
@media (max-width: 620px) {
}
/*---------------------------------------------------------------------------BP480*/
@media (max-width: 480px) {
}
