/* Scss Document */

////////////////////////////////*■■■SCSS形式で作成■■■*////////////////////////////////

@import "setting";
@import "../../../../assets/scss/dialogs.scss";

/***▼▼▼窓の中身『上段／コンテンツ部分』エリア切替用***/
.popup_map_areaselect {
  @include popup_map_areaselect;
  .ctt {
    @include popup_map_areaselect_ctt;
    &:last-child {
      @include popup_map_areaselect_ctt_last;
    }
  }
  .ken {
    @include popup_map_ken;
  }
  .dtl {
    @include popup_map_dtl;
    .other {
      @include popup_map_dtl_other;
    }
    .area {
      @include popup_map_dtl_area;
    }
  }
}
/***▲▲▲窓の中身『上段／コンテンツ部分』エリア切替用***/

.prefBox {
  margin-top: 0 !important;
  margin-bottom: 2em !important;

  &:last-child {
    margin-bottom: 0 !important;
  }

  .prefName {
    text-align: center !important;
    font-size: 1.2em !important;
    font-weight: 700 !important;
    margin-bottom: 0.5em !important;
  }

  .areaName {
    text-align: center !important;
    font-size: 0.9em !important;
    font-weight: 700 !important;
    margin-bottom: 0.5em !important;
  }
}

.backdrop {
  background-color: rgba(255, 255, 255, 0.75) !important;
}

.okButton {
  border-radius: 40px !important;
  border: solid 4px black !important;
  background-color: $col-ki !important;
  color: black !important;
  font-weight: 700 !important;
  margin-right: 0.5em !important;
  width: 120px !important;

  &:hover {
    background-color: $col-ki-d !important;
  }

  &:disabled {
    background-color: $col-hai-l !important;
    border-color: $col-hai !important;
    color: $col-hai !important;
  }
}

.cancelButton {
  border-radius: 40px !important;
  border: solid 4px black !important;
  background-color: white !important;
  color: black !important;
  font-weight: 700 !important;
  margin-left: 0.5em !important;
  width: 120px !important;

  &:hover {
    background-color: $col-hai-l !important;
  }
}
