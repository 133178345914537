*[class$="-marker-view"] {
  &:focus {
    outline: none;
  }
}

.estate-marker {
  position: relative;
  width: 38px;
  height: 44px;
  cursor: pointer;
  opacity: 0.8;
  transition: transform 0.3s ease;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      transform: scale(1.2);
    }
  }

  &.info_clicked {
    animation: bounce 0.5s infinite;
  }

  .estate-icon {
    width: 38px;
    height: 44px;
  }
}

.sold-estate-marker {
  position: absolute;
  height: 31px;
}

.marker-clusterer {
  cursor: pointer;
  opacity: 0.8;
  transition: transform 0.3s ease;

  img {
    width: 36px;
    height: 36px;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      transform: scale(1.2);
    }
  }
}

.my-position-marker {
  width: 36px;
  height: 36px;
  cursor: pointer;
  opacity: 0.8;
  transition: transform 0.3s ease;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      transform: scale(1.2);
    }
  }
}

.route-point-marker {
  position: absolute;
  box-sizing: border-box;
  left: -16px;
  top: -16px;
  width: 32px;
  height: 32px;
  cursor: pointer;
  background-color: #d93751;
  color: #ffffff;
  display: inline-block;
  opacity: 0.8;
  border-radius: 50%;
  border-color: #ffffff;
  border-width: 2px;
  border-style: solid;

  div {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    font-size: 130%;
  }
}

.measure-point-marker {
  position: absolute;
  box-sizing: border-box;
  left: calc(32px / 6 * -1);
  top: calc(32px / 6 * -1);
  width: calc(32px / 3);
  height: calc(32px / 3);
  cursor: pointer;
  background-color: #ffffff;
  color: #ffffff;
  display: inline-block;
  opacity: 1;
  border-radius: 50%;
  border-color: #ff0000;
  border-width: 1px;
  border-style: solid;
}

.sticky-note-marker {
  width: 36px;
  height: 32px;
  cursor: pointer;
  opacity: 0.8;
  transition: transform 0.3s ease;

  img {
    width: 36px;
    height: 32px;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      transform: scale(1.2);
    }
  }

  &.clicked {
    animation: bounce 0.5s infinite;
  }
}

.school-point-marker {
  width: 38px;
  height: 32px;
  cursor: pointer;
  transition: transform 0.3s ease;

  img {
    width: 38px;
    height: 32px;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      transform: scale(1.2);
    }
  }
}

@keyframes bounce {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
