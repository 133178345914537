@import "setting";

.root {
  div[role="presentation"] {
    @include twbasic01;
    * {
      @include twbasic01_all;
      &:focus {
        @include twbasic01_focus;
      }
    }
  }
}

.backdrop {
  background-color: rgba(255, 255, 255, 0.75) !important;
}

.dialogPaper {
  position: relative;
  border-radius: 40px !important;
  border: 5px solid #000;
  padding: 2em 0 1em 0;
  margin-top: calc(var(--headerHeight) * 1.7) !important;
  overflow-y: visible !important;

  .alert {
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .icon {
    padding: 0;
  }

  .dialogContent {
    width: clamp(50vw, 80vh, 85vw);
    height: calc(100dvh - var(--headerHeight) - 12rem - 5vh);
    overflow-x: hidden !important;
    padding: 20px 5%;

    * {
      font-family: inherit !important;
      font-weight: 500;
    }

    .bkn_info {
      margin-bottom: 1.5em;

      > .box {
        display: flex;
        background-color: #ededed;
      }

      .icon,
      .dtl .tit,
      .dtl .tx {
        border: 2px solid #fff;
        line-height: 1;
        overflow: hidden;
        white-space: nowrap;
      }

      .icon {
        border: 2px solid #fff;
        aspect-ratio: 1/1;
        text-align: center;
        padding: 0.75em;
        width: 5em;

        img {
          height: 100%;
          width: auto;
        }
      }

      .dtl {
        width: calc(100% - 5em);
        > .box {
          display: flex;
          align-items: center;
          height: 50%;
        }

        .tit,
        .tx {
          padding: 0 0.75em;
          display: flex;
          align-items: center;
          height: 100%;
        }

        .tit {
          width: 8.5em;
        }

        .tx {
          width: calc(100% - 8.5em);
        }
      }
    }

    .bkn_info2 {
      margin-bottom: 1.5em;

      > .box {
        display: flex;
        align-items: center;
        height: 50%;
        width: 100%;
        background-color: #ededed;
      }

      .tit,
      .tx {
        height: 2.5em;
        padding: 0 0.75em;
        line-height: 1;
        display: flex;
        align-items: center;
        overflow: hidden;
        white-space: nowrap;
        border: 2px solid white;
      }

      .tit {
        width: 8.5em;
      }

      .tx {
        width: calc(100% - 8.5em);
      }
    }

    .ki_maru_list {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        padding: 0;
        margin: 0 0 0.5em 0;
        display: flex;
        line-height: 1.35;
        align-items: center;

        &::before {
          content: "";
          width: 1.2em;
          min-width: 1.2em;
          aspect-ratio: 1/1;
          background-color: #fff000;
          border-radius: 100px;
          display: inline-block;
          margin: 0.25em;
        }

        & > div {
          display: inline-flex;
          align-items: center;

          button {
            min-width: 150px;
          }
        }
      }
    }
  }

  .actions {
    height: 5em;
    display: flex;
    justify-content: center;
    margin-top: 1em;

    .okButton {
      border-radius: 40px;
      border: solid 4px black;
      background-color: $col-ki;
      color: black;
      font-weight: 700;
      width: 120px;

      &:hover {
        background-color: $col-ki-d;
      }
    }

    .adminEditButton {
      border-radius: 40px;
      border: solid 4px black;
      background-color: white;
      color: black;
      font-weight: 700;
      width: 200px;

      &:hover {
        background-color: lightgray;
      }
    }

    .cancelButton {
      border-radius: 40px;
      border: solid 4px black;
      background-color: white;
      color: black;
      font-weight: 700;
      width: 120px;

      &:hover {
        background-color: $col-hai-l;
      }
    }
  }

  .errorMessage {
    color: red;
  }

  .separator {
    margin: 1em 0 1em 0;
  }

  .formLabel {
    color: black;
    margin-bottom: 0.8em;

    .warning {
      color: red;
      font-weight: 700;
    }
  }

  .fieldWrapper {
    margin-left: 1em;

    .field {
      border: 2px solid black;
      border-radius: 0.5rem;
      padding: 2px;

      &.company {
        padding: 0;

        input {
          margin-top: 4px;
        }

        button {
          margin-top: 4px;
        }
      }
    }

    .place {
      margin-bottom: 0.5em;
      display: inline-flex;
      align-items: center;
      width: 100%;

      .field {
        width: calc(100% - 180px);
      }

      .addressToMapButton {
        width: 180px;
        margin-left: 0.5em;
        color: black !important;
        border-color: black !important;
        border-radius: 0.5rem !important;
        border-style: solid;
        border-width: 2px;
        box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2) !important;

        .buttonIcon {
          width: 2em;
          padding-right: 0.5em;
        }
      }
    }
  }

  .mapWrapper {
    border: 2px solid black;
    border-radius: 0.5rem;
    padding: 10px;

    .map {
      min-width: 100%;
      max-height: 70dvh;
      height: 500px;
    }
  }
}

.bottomError {
  margin: 0 2em;
  color: red;
  font-weight: 700;
  text-align: center;
}
