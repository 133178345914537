$borderStyle: 1px solid #aaa082;
$rowHeight: 4.6%;

.waiting {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100dvw;
  height: 100dvh;
}

.detail {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;

  .section {
    overflow: auto;

    .individualPage {
      box-sizing: border-box;
      padding: 20px;
      overflow: hidden;
      width: calc(297mm - 6mm);
      height: calc(210mm - 6mm);

      .main {
        height: 100%;

        .content {
          box-sizing: border-box;
          height: 90%;
          display: grid;
          grid-template-columns: 40% 60%;

          .information {
            display: block;
            box-sizing: border-box;

            .notFound {
              box-sizing: border-box;

              padding: 10px;
              overflow: hidden;

              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 20px;

              height: 100%;

              animation-name: fade-in;
              animation-duration: 3s;
              animation-timing-function: ease-in;
            }
          }

          .map {
            box-sizing: border-box;
          }
        }

        .company {
          box-sizing: border-box;
          height: 10%;
        }
      }
    }
  }
}

.completed {
  display: none;
}

@media screen and (orientation: portrait) {
  .detail {
    .section {
      .individualPage {
        width: 100dvw;
        height: unset;

        .main {
          .content {
            display: block;

            .information {
              border-bottom: none;
            }

            .map {
              border: $borderStyle;
              border-top: none;
              border-right: none;
            }
          }
        }
      }
    }
  }
}

@media print {
  .detail {
    #for-calc {
      display: none !important;
    }

    .MuiBackdrop-root {
      display: none !important;
    }

    .individualPage {
      page-break-after: always;
      box-sizing: border-box;
      width: calc(297mm - 6mm);
      height: calc(210mm - 6mm);
      padding-top: 20mm;
      padding-bottom: 5mm;
      overflow: hidden;
    }

    .individualPage:last-child {
      page-break-after: avoid;
    }
  }

  .printButton {
    display: none;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  80% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
