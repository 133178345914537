@import "setting";

.cardWrapper {
  position: absolute;
  top: calc(
    var(--headerHeight) + var(--menuButtonHeight) + var(--dialogMargin) + 10px
  );
  left: calc(
    var(--frameWidth) + var(--drawerMinWidth) + var(--dialogMargin) + 0px
  );
}

.dialogPaper {
  border-radius: 40px !important;
  border: solid 4px black;
  padding: 2em 0 1em 0;
  overflow: inherit !important;
  overflow-y: visible !important;
  transition: all ease-out 0.1s;

  width: clamp(
    calc(1vw - var(--frameWidth) * 2 - var(--dialogMargin) * 2),
    12rem,
    calc(100vw - var(--frameWidth) * 2 - var(--dialogMargin) * 2)
  );
  max-height: calc(
    100dvh - var(--headerHeight) - var(--menuButtonHeight) - var(--frameWidth) -
      var(--dialogMargin) * 2
  );

  &.expand {
    width: clamp(
      calc(1vw - var(--frameWidth) * 2 - var(--dialogMargin) * 2),
      25rem,
      calc(100vw - var(--frameWidth) * 2 - var(--dialogMargin) * 2)
    );
  }

  &.mobile {
    left: calc(
      var(--frameWidth) + var(--drawerMinWidth) + var(--dialogMargin) + 0px
    );
  }

  .header {
    position: absolute;
    top: -20px;
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;

    & > svg {
      background-color: white;
      border: 5px solid black;
      border-radius: 50%;
      width: 40px;
      height: 40px;

      &:hover {
        cursor: grab;
      }

      &:active {
        cursor: grabbing;
      }
    }

    @mixin dialogButton {
      position: absolute;
      top: 4px;
      width: 30px !important;
      height: 30px !important;
      background-color: white;
      border: 3px solid black;
      border-radius: 50%;
      padding: 5px;
      color: black;
      transform: rotate(0deg) scale(1) !important;
      transition: transform 0.3s !important;

      &:hover {
        transform: rotate(180deg) scale(1.2) !important;
        transition: transform 0.3s !important;
      }
    }

    .closeButton {
      right: 34px;
      @include dialogButton;
    }

    .expandButton {
      right: 0px;
      @include dialogButton;
    }
  }

  &.expand {
    .header {
      top: -32px;
      height: 64px;

      & > svg {
        width: 64px;
        height: 64px;
      }

      .closeButton,
      .expandButton {
        top: 15px;
      }
    }
  }

  .route {
    font-size: 80%;

    .routeInfo {
      text-align: center;
    }
  }

  .resultItem {
    background-color: white;
    color: black;
    font-size: 80%;
    border: none;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    box-shadow: none;
  }

  .item {
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    background-color: $col-ki-l;
    color: black;
    font-size: 80%;
    border-radius: 20px;
    border: solid 2px black;

    .info {
      cursor: pointer;
    }
  }

  .expandIcon {
    transform: rotateX(0deg);
    transition: all 0.3s;

    &.expand {
      transform: rotateX(180deg);
    }
  }

  .content {
    max-height: 0px;
    padding: 0;
    transition: all 0.3s;
    overflow: hidden;

    &.expand {
      max-height: initial;
      padding: initial;
    }

    .routes {
      padding: 0.5rem;
      overflow-y: auto;
      max-height: calc(
        100dvh - var(--headerHeight) * 2 - var(--menuButtonHeight) - 12rem
      );
    }
  }

  .switch {
    height: 2rem;
  }

  .actions {
    display: flex;
    justify-content: center;
    margin-top: 0.5em;

    .button {
      margin: 0 0.5em;
    }
  }

  .buttons {
    height: 5rem;
  }

  .button {
    border-radius: 40px;
    border: solid 4px black;
    background-color: $col-ki;
    color: black;
    font-weight: 700;

    &:hover {
      background-color: $col-ki-d;
    }

    &.active {
      background-color: $col-ki;
    }

    &.deactive {
      background-color: white;
    }

    &.googleMap,
    &.qrCode {
      background-color: white;
    }
  }
}
