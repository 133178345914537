@import "setting";

.cardWrapper {
  position: absolute;
  bottom: calc(var(--frameWidth) + var(--dialogMargin));
  right: calc(
    var(--frameWidth) + var(--drawerMinWidth) + var(--dialogMargin) + 2.8em
  );
}

.usageGuideDialog {
  z-index: 100;
  border-radius: 20px !important;
  border: solid 2px black;
  overflow: visible !important;
  transition: all ease-out 0.2s;

  width: clamp(
    calc(1vw - var(--frameWidth) * 2 - var(--dialogMargin) * 2),
    8.5rem,
    calc(100vw - var(--frameWidth) * 2 - var(--dialogMargin) * 2)
  );
  max-height: 3.4rem;

  &.expand {
    width: clamp(
      calc(1vw - var(--frameWidth) * 2 - var(--dialogMargin) * 2),
      14rem,
      calc(100vw - var(--frameWidth) * 2 - var(--dialogMargin) * 2)
    );
    max-height: 25rem;
  }

  .contentWrapper {
    margin: 0;
    padding: 0.5rem;

    .content {
      height: 2rem;

      &:hover {
        cursor: grab;
      }

      &:active {
        cursor: grabbing;
      }
    }
  }

  .row {
    width: 100%;
    height: 100%;

    border-style: solid;
    border-width: 1.5px;
    border-radius: 10px;
  }

  .caption {
    font-size: calc(11.2rem / 14);
  }

  .onOffButton {
    border-radius: 20px;
    border: solid 2px black;
    background-color: $col-ki;
    color: black;
    font-weight: 700;
    margin-right: 0.8em;

    &:hover {
      background-color: $col-ki-d;
    }
  }

  .expandButton {
    position: absolute;
    top: -15px;
    right: -17px;
    background-color: white !important;

    border: 2px solid black;
    border-radius: 50%;
    padding: 2px;

    .expandIcon {
      transform: rotate(180deg) scale(1) !important;
      transition: transform 0.3s !important;

      &:hover {
        transform: rotate(0deg) scale(1.2) !important;
        transition: transform 0.3s !important;
      }

      &.expand {
        transform: rotateX(0deg);
      }
    }
  }

  .chartWrapper {
    padding: 0.5rem;
    overflow-y: auto;
    max-height: 0px;
    transition: all 0.3s;

    .chart {
      height: 0;
      overflow: hidden;
    }

    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }

    &.expand {
      max-height: 22rem;
      scrollbar-width: thin;
      &::-webkit-scrollbar {
        display: block;
      }

      .chart {
        height: unset;
        overflow: unset;
      }
    }
  }
}
