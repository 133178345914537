.paperScrollPaper {
  background-color: transparent !important;
  box-shadow: none !important;
}

.paper,
.container {
  background-color: transparent;
  box-shadow: none;
  padding: 0 !important;

  .popup_map {
    left: 0;

    .btn,
    .btn_sub_close {
      cursor: pointer;
    }
  }

  .popup_map_filter {
    .ctt:nth-of-type(2),
    .ctt:nth-of-type(3),
    .ctt:nth-of-type(5) {
      margin-bottom: 0 !important;
    }
  }
}

.paper {
  margin: 0 !important;
  max-width: none !important;
  width: 100% !important;
  min-height: calc(100dvh - var(--headerHeight)) !important;
  justify-content: center;
}

.container {
  height: calc(100dvh - var(--headerHeight)) !important;
  margin-top: var(--headerHeight) !important;
}

/***▼▼▼窓のデザイン***/
.popup_map {
  @include pum;
  &.on {
    @include pum_on;
  }
  .scroll {
    @include pum_scroll;
    > .tit,
    > .box {
      @include pum_tit_box;
    }
    > .tit {
      @include pum_tit;
      span {
        @include pum_tit_span;
        img {
          @include pum_tit_span_img;
        }
      }
    }
    > .box {
      @include pum_box;
      .box2 {
        @include pum_box2;
        .ovfl {
          @include pum_ovfl;
        }
      }
    }
    .btn_sub_close {
      @include pum_btn_sub_close;
    }
  }
}
/***▲▲▲窓のデザイン***/

/***▼▼▼窓の中身『下段／ボタン系部分』***/
.popup_map_btn {
  @include pum_btn;
  .btn {
    @include pum_btn_btn;
    &:hover {
      @include pum_btn_btn_hov;
      &::before {
        @include pum_btn_btn_hov_bef;
      }
    }
    &.on {
      @include pum_btn_btn_on;
    }
    &.off {
      @include pum_btn_btn_off;
      &:hover {
        @include pum_btn_btn_off_hov;
        &::before {
          @include pum_btn_btn_off_hov_bef;
        }
      }
    }
    &::before {
      @include pum_btn_btn_bef;
    }
    &.cancel {
      @include pum_btn_btn_cancel;
      &::before {
        @include pum_btn_btn_cancel_bef;
      }
    }
    &.reset {
      @include pum_btn_btn_reset;
      &::before {
        @include pum_btn_btn_reset_bef;
      }
    }
    &.arrow {
      @include pum_btn_btn_arrow;
      &::before {
        @include pum_btn_btn_arrow_bef;
      }
      &.off {
        @include pum_btn_btn_arrow_off;
        &:hover {
          @include pum_btn_btn_arrow_off_hov;
          &::before {
            @include pum_btn_btn_arrow_off_hov_bef;
          }
        }
      }
    }
  }
}
/***▲▲▲窓の中身『下段／ボタン系部分』***/

/***▼▼▼ON/OFFボタンデザイン01***/
.btn_switch01 {
  @include btn_switch01;
  &:hover {
    @include btn_switch01_hover;
  }
  &.on {
    @include btn_switch01_on;
  }
  &.tit {
    @include btn_switch01_tit;
    &.on {
      @include btn_switch01_tit_on;
    }
  }
}
/***▲▲▲ON/OFFボタンデザイン01***/

/***▼▼▼POPUPボタンデザイン02***/
.btn_pum02 {
  @include btn_pum02;
  &.on {
    &:hover {
      @include btn_pum02_on_hov;
    }
  }
  &.off {
    @include btn_pum02_off;
    &:active {
      @include btn_pum02_off_act;
    }
  }
}
/***▲▲▲POPUPボタンデザイン02***/

/***▼▼▼クリック時のアクション01***/
.click_act01 {
  &:active {
    @include click_act01_act;
  }
}
/***▲▲▲ON/OFFボタン01***/

/*---------------------------------------------------------------------------BP1920*/
@media (max-width: 1920px) {
}
/*---------------------------------------------------------------------------BP1600*/
@media (max-width: 1600px) {
}
/*---------------------------------------------------------------------------BP1280*/
@media (max-width: 1280px) {
}
/*---------------------------------------------------------------------------BP1024*/
@media (max-width: 1024px) {
}
/*---------------------------------------------------------------------------BP1023*/
@media (max-width: 1023px) {
  /***▼▼▼窓のデザイン***/
  .popup_map {
    @include rd1023_pum;
    // &.on {}
    .scroll {
      @include rd1023_pum_scroll;
      > .tit {
        @include rd1023_pum_tit;
        // img {}
      }
      > .box {
        @include rd1023_pum_box;
        // .box2 {
        //   .ovfl {}
        // }
      }
      .btn_sub_close {
        @include rd1023_pum_btn_sub_close;
        img,
        b {
          @include rd1023_pum_btn_sub_close_img_b;
        }
        img {
          @include rd1023_pum_btn_sub_close_img;
        }
        b {
          @include rd1023_pum_btn_sub_close_b;
        }
        &:hover {
          img {
            @include rd1023_pum_btn_sub_close_hov_img;
          }
          b {
            @include rd1023_pum_btn_sub_close_hov_b;
          }
        }
      }
    }
  }
  /***▲▲▲窓のデザイン***/
}
/*---------------------------------------------------------------------------BP960*/
@media (max-width: 960px) {
}
/*---------------------------------------------------------------------------BP896*/
@media (max-width: 896px) {
}
/*---------------------------------------------------------------------------BP768*/
@media (max-width: 768px) {
}
/*---------------------------------------------------------------------------BP767*/
@media (max-width: 767px) {
}
/*---------------------------------------------------------------------------BP620*/
@media (max-width: 620px) {
  /***▼▼▼窓のデザイン***/
  .popup_map {
    @include rd620_pum;

    height: calc(100% - var(--headerHeight)) !important;
    min-height: calc(100% - var(--headerHeight)) !important;
    top: var(--headerHeight) !important;

    &.on {
      @include rd620_pum_on;
    }
    .scroll {
      @include rd620_pum_scroll;
      &::-webkit-scrollbar {
        @include rd620_pum_scroll_scrollbar;
      }
      > .tit {
        @include rd620_pum_tit;
        span {
          @include rd620_pum_tit_span;
          img {
            @include rd620_pum_tit_img;
          }
        }
      }
      > .box {
        @include rd620_pum_box;
        .box2 {
          @include rd620_pum_box2;
          .ovfl {
            @include rd620_pum_ovfl;
          }
        }
      }
      .btn_sub_close {
        @include rd620_pum_btn_sub_close;
      }
    }
  }
  /***▲▲▲窓のデザイン***/

  /***▼▼▼窓の中身『上段／コンテンツ部分』絞り込み用***/
  .popup_map_filter {
    .select {
      .tit {
        span {
          @include rd620_pum_filter_select_tit_span;
        }
      }

      .dtl {
        @include rd620_pum_filter_select_dtl;
      }
    }
  }
  /***▲▲▲窓の中身『上段／コンテンツ部分』絞り込み用***/

  /***▼▼▼窓の中身『下段／ボタン系部分』***/
  .popup_map_btn {
    @include rd640_pum_btn;
    .btn {
      @include rd640_pum_btn_btn;
    }
    &.btn_3ko {
      @include rd640_pum_btn_btn_3ko;
      .btn {
        @include rd640_pum_btn_btn_3ko_btn;
        &.arrow {
          @include rd640_pum_btn_btn_3ko_btn_arrow;
        }
        &.reset,
        &.cancel {
          @include rd640_pum_btn_btn_3ko_btn_reset_cancel;
        }
      }
    }
  }
  /***▲▲▲窓の中身『下段／ボタン系部分』***/
}
/*---------------------------------------------------------------------------BP480*/
@media (max-width: 480px) {
}
