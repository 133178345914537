.alert {
  opacity: 0.95;

  position: absolute;
  top: 10dvh;
  left: 3vw;
  right: 3vw;
  z-index: 10000;
}

.trialExpired {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100dvw;
  height: 100dvh;
  padding: 2em;

  .trialExpiredInner {
    border: 3px solid #000;
    border-radius: 50px;
    font-weight: bold;

    padding: 0.5em 3em 2em 3em;

    .logo {
      text-align: center;
      padding: 0.5em;

      img {
        width: 100px;
      }
    }

    .title {
      text-align: center;
      padding: 0.5em;
      font-size: 1.5em;
      font-weight: bold;
    }

    .message {
      text-align: left;
      padding: 0.5em;
      font-size: 1em;

      .button {
        margin: 2em 0;
        text-align: center;

        a,
        button {
          display: inline-flex;
          background-color: darkorange;
          color: #fff;
          border: none;
          border-radius: 10px;
          padding: 0.5em 2em;
          font-weight: bold;
          text-decoration: none;
          cursor: pointer;

          &.signOut {
            background-color: #f0f0f0;
            color: black;
          }
        }
      }
    }
  }
}
