.label {
  color: black;
}

@import "setting";
@import "../../../../../../assets/scss/dialogs.scss";

.btn_square {
  border-radius: 10px;
  width: 3rem;
  height: 2.5rem;
  padding: 6px;
  margin: 0 0.5rem 0.5rem 0;

  img {
    margin: 0;
    width: 100%;
    height: 100%;
  }
}

.wrapper {
  margin-left: 1em;

  display: flex;
  flex-wrap: wrap;

  * {
    font-weight: 700 !important;
  }
}
