.progress_wrapper {
  margin-top: 20px;
  margin-bottom: 20px;

  .progress {
    height: 20px;
    background-color: rgba(170, 160, 130, 1);
    border-radius: 20px;
    border: 2px solid black;
  }
}

.message {
  font-size: 60%;
}
