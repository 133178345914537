.headerBar {
  display: flex;
  justify-content: center;
  margin-bottom: 1em;
  svg {
    width: 250px;
    height: calc(250px / 100 * 71);

    @media screen and (max-width: 768px) {
      width: 180px;
      height: calc(180px / 100 * 71);
    }
  }
}

.footerBar {
  margin-top: 1em;
}
