/* Scss Document */

////////////////////////////////*■■■SCSS形式で作成■■■*////////////////////////////////

@import "setting";

figure {
  margin: 0;
  padding: 0;
  overflow: hidden;
}
img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  border: 0;
}

.twbasic01 {
  @include twbasic01;
  * {
    @include twbasic01_all;
    &:focus {
      @include twbasic01_focus;
    }
  }
}

/*---------------------------------------------------------------------------BP1920*/
@media (max-width: 1920px) {
}
/*---------------------------------------------------------------------------BP1600*/
@media (max-width: 1600px) {
}
/*---------------------------------------------------------------------------BP1280*/
@media (max-width: 1280px) {
}
/*---------------------------------------------------------------------------BP1024*/
@media (max-width: 1024px) {
}
/*---------------------------------------------------------------------------BP1023*/
@media (max-width: 1023px) {
}
/*---------------------------------------------------------------------------BP960*/
@media (max-width: 960px) {
}
/*---------------------------------------------------------------------------BP896*/
@media (max-width: 896px) {
}
/*---------------------------------------------------------------------------BP768*/
@media (max-width: 768px) {
}
/*---------------------------------------------------------------------------BP767*/
@media (max-width: 767px) {
}
/*---------------------------------------------------------------------------BP620*/
@media (max-width: 620px) {
  .twbasic01 {
    font-size: 14px;
  }
}
/*---------------------------------------------------------------------------BP480*/
@media (max-width: 480px) {
}
