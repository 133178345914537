@import "assets/scss/basic.scss";

:root {
  // ヘッダ高さ
  --headerHeight: 50px;

  // マップの枠線
  --frameWidth: 0px;

  // ダイアログのマージン
  --dialogMargin: 10px;

  // ドロワーの幅
  --drawerMinWidth: 0px;

  // メニューボタンの高さ
  --menuButtonHeight: 80px;

  // マーカーの最大z-index
  --markerMaxZIndex: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;

  .pac-container {
    z-index: 10000 !important;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.memo-marker-label {
  font-family: "Lato", zen-maru-gothic, sans-serif;
  transform: scale(0.6) translate(26px, 18px);
}

[class*="amplify"] {
  font-family: "Lato", zen-maru-gothic, sans-serif !important;
}

[class^="amplify-storagemanager"] {
  &.amplify-storagemanager__previewer,
  &.amplify-storagemanager__dropzone {
    border: none;
  }
}

.gm-style iframe + div {
  border: none !important;
}

@import "assets/scss/select_circle.scss";
@import "assets/scss/icon_number.scss";
@import "assets/scss/icon_label.scss";
@import "assets/scss/icon_ud.scss";
@import "assets/scss/icon_circle.scss";
@import "assets/scss/icon_up_label.scss";
@import "assets/scss/marker_clusterer_label.scss";
@import "assets/scss/tile16.scss";
@import "assets/scss/map_grid.scss";
@import "assets/scss/school_point_marker_label.scss";
@import "assets/scss/school_name.scss";
@import "assets/scss/measure_info_window.scss";
@import "assets/scss/markers.scss";
@import "assets/scss/estate_info.scss";
@import "assets/scss/moj_info_window.scss";
@import "assets/scss/sticky_note.scss";

@import "react-nice-dates/build/style.css";

.nice-dates-day_date,
.nice-dates-navigation_current {
  font-weight: bold;
}

.nice-dates-today {
  &::before,
  &::after {
    opacity: 0.5;
    background-color: lightgray;
    border: none;
  }
}

.nice-dates-day {
  &.-selected {
    &::before,
    &::after {
      border: 2px solid black !important;
      background-color: $col-ki !important;
    }

    .nice-dates-day_date {
      color: black;
    }
  }
}

.nice-dates-navigation_previous:before {
  transform: rotate(-45deg);
}

.nice-dates-navigation_next:before {
  margin-top: -16px;
  transform: rotate(135deg);
}
