.estate-info-container {
  position: absolute;
  top: 20px;
  left: 20px;

  border: 3px solid #000;
  border-radius: 10px;

  .link {
    position: relative;

    .tooltip {
      opacity: 0;
      visibility: hidden;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 30px;
      display: inline-block;
      padding: 5px;
      white-space: nowrap;
      font-size: 0.7rem;
      line-height: 1.3;
      background: #333;
      color: #fff;
      border-radius: 5px;
      transition: 0.3s ease-in;
    }

    &:hover {
      .tooltip {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .close {
    cursor: pointer;
    position: absolute;
    top: -15px;
    right: -15px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 3px solid #000;
    background-color: #fff;

    display: flex;
    justify-content: center;
    align-items: center;

    z-index: 1;

    img {
      width: 20px;
      height: 20px;
    }
  }

  .block-wrapper {
    width: 300px;
    max-height: min(500px, 80vh);
    border-radius: 6px;
    overflow-y: scroll;
    background-color: white;

    .one-block {
      overflow-x: hidden;

      h3 {
        margin: 0;
        padding: 0.5em;
        font-size: 100%;
        font-weight: bold;
        text-align: center;
        border-top: solid 3px #000;
      }

      &.ground {
        h3 {
          background-color: $col-ic-gro;
          color: #fff;
        }
      }

      &.block {
        h3 {
          background-color: $col-ic-blo;
          color: #fff;
        }
      }

      &.new {
        h3 {
          background-color: $col-ic-new;
          color: #fff;
        }
      }

      &.used {
        h3 {
          background-color: $col-ic-use;
          color: #fff;
        }
      }

      &.apartment {
        h3 {
          background-color: $col-ic-apa;
          color: #fff;
        }
      }

      &.business {
        h3 {
          background-color: $col-ic-bus;
          color: #fff;
        }
      }

      &:first-child {
        h3 {
          border-top: none;
        }
      }

      .subtitle {
        text-align: center;
        margin: 10px 0;

        ul {
          display: inline-flex;
          align-items: center;
          margin: 0;
          padding: 0;

          li {
            display: inline;
            font-size: 90%;
            font-weight: normal;

            &.public {
              font-size: 80%;
              color: #33f;
              border: 1px solid #33f;
              border-radius: 5px;
              padding: 2px 10px;
              margin-right: 15px;
            }

            &.non-public {
              font-size: 80%;
              color: #666;
              border: 1px solid #666;
              border-radius: 5px;
              padding: 2px 10px;
              margin-right: 15px;
            }

            &.no {
              font-size: 74%;
              margin-right: 15px;
            }

            &.display-count {
              display: inline-flex;
              align-items: center;

              span {
                font-size: 0.8em;
              }

              img {
                width: 20px;
                height: 20px;
                margin-right: 5px;
              }
            }

            &.external {
              margin-left: 10px;
              display: inline-flex;
              align-items: center;

              a {
                margin: 0;
                padding: 0;
                height: 20px;

                img {
                  width: 20px;
                  height: 20px;
                }
              }
            }
          }
        }
      }

      .address {
        margin: 10px 0 0 0;
        padding: 10px 0;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;

        ul {
          margin: 0;
          padding: 0;

          li {
            text-align: center;
            list-style: none;

            font-size: 80%;

            &:nth-child(2) {
              font-size: 70%;
            }
          }
        }
      }

      .amount,
      .info {
        margin: 0;
        border-bottom: 1px solid #ccc;

        ul {
          display: grid;
          grid-template-columns: 1fr 1fr;
          align-items: center;
          margin: 0;
          padding: 0;

          li {
            text-align: center;
            list-style: none;

            span {
              margin-left: 5px;
              font-size: 12px;
              font-weight: normal;
            }

            &.price {
              padding: 10px 0;
              border-bottom: #ccc dotted 1px;
              font-size: 200%;
              font-weight: bold;

              grid-column-start: 1;
              grid-column-end: 3;

              .down,
              .up {
                display: inline-flex;
                align-items: center;
                font-size: 100%;
                font-weight: bold;

                .at {
                  color: #000;
                  font-size: 8px;
                  font-weight: normal;
                }
              }

              .down {
                color: #00f;
              }

              .up {
                color: #f00;
              }
            }

            &.area,
            &.price-per-area {
              padding: 10px 0;
              font-size: 120%;
              font-weight: bold;

              span {
                margin-right: 5px;
                font-weight: normal;
              }
            }

            &.company,
            &.created-at {
              grid-column-start: 1;
              grid-column-end: 3;
            }

            &.company {
              font-size: 90%;
            }

            &.created-at {
              font-size: 70%;
            }
          }
        }
      }

      .layout {
        margin: 0;
        padding: 5px 0;
        border-bottom: 1px solid #ccc;
        text-align: center;

        ul {
          display: inline-flex;
          margin: 0;
          padding: 0;

          li {
            text-align: center;
            list-style: none;

            font-size: 90%;
          }
        }
      }

      .condition {
        margin: 0;
        padding: 5px 0;
        border-bottom: 1px solid #ccc;
        text-align: center;

        ul {
          display: inline-flex;
          margin: 0;
          padding: 0;

          li {
            text-align: center;
            list-style: none;
            margin: 0.5em;
            padding: 0.2em 0.5em;
            border: solid 1px #cc0;
            border-radius: 15px;
            background-color: #ffc;

            font-size: 80%;
          }
        }
      }

      .buttons {
        margin: 0;
        padding: 5px 0;
        text-align: center;
        background-color: #ffc;

        ul {
          display: inline-flex;
          margin: 0;
          padding: 0;

          li {
            cursor: pointer;
            text-align: center;
            list-style: none;
            margin: 0.5em;

            a {
              font-size: 80%;
              margin: 0;
              padding: 0;
              height: 20px;

              img {
                width: 20px;
                height: 20px;
              }
            }
          }
        }
      }
    }
  }
}
