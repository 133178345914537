.wrapper {
  height: calc(100dvh - var(--headerHeight));

  .searchMap {
    width: 100%;
    height: 100%;
  }

  .mapInfo {
    position: absolute;
    bottom: 0px;
    left: 80px;
    font-size: 10px;
    background-color: rgb(255, 255, 255, 0.5);
    padding-left: 3px;
    padding-right: 3px;
  }
}

@media screen and (max-width: 710px) {
  .wrapper {
    .mapInfo {
      background: none;
      left: 10px;
      bottom: 24px;
      line-height: 0.8em;
    }
  }
}
