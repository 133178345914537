@import "setting";

.backdrop {
  background-color: rgba(255, 255, 255, 0.75) !important;
}

.headerIcon {
  padding: 2px;

  svg {
    border: none !important;
    border-radius: 0 !important;
    background-color: transparent !important;
    width: 45px !important;
    height: 45px !important;
  }
}

.dialogPaper {
  position: relative;
  border-radius: 40px !important;
  border: solid 4px black;
  padding: 2em 0 1em 0;
  overflow-y: visible !important;

  margin-top: calc(var(--headerHeight) + 64px);

  .alert {
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .content {
    width: clamp(50vw, 80vh, 85vw);
    max-width: calc(100% - 31px);
    height: calc(100dvh - var(--headerHeight) - 16rem - 5vh);
    margin-left: 1em;
    margin-right: 1em;
    border-radius: 5px;
    box-shadow: inset 0px 0px 5px 0px rgba(0, 0, 0, 0.2);

    .container {
      padding-top: 1em;

      .subject {
        font-size: 1.5em;
        font-weight: 700;
      }

      .createdAt {
        text-align: right;
        color: gray;
      }
    }
  }

  .actions {
    display: flex;
    justify-content: center;
    height: 4rem;

    .checkbox {
      width: 100%;
      text-align: center;
    }

    .button {
      border-radius: 40px;
      border: solid 4px black;
      color: black;
      font-weight: 700;
      width: 240px;

      &.ok {
        background-color: $col-ki;
        &:hover {
          background-color: $col-ki-d;
        }

        &:disabled {
          border-color: gray;
          background-color: lightgray;
          color: gray;
        }
      }

      &.logout {
        background-color: white;
        &:hover {
          background-color: lightgray;
        }
      }
    }
  }
}
