/* Scss Document */

////////////////////////////////*■■■SCSS形式で作成■■■*////////////////////////////////

@import "setting";

/********************メッシュタイル********************/
$col-grid01: rgba(100, 100, 100, 0.7);

.tile16 {
  div {
    position: absolute;

    &.b_left {
      border-left: 1px solid $col-grid01;
    }

    &.b_right {
      border-right: 1px solid $col-grid01;
    }

    &.b_top {
      border-top: 1px solid $col-grid01;
    }

    &.b_bottom {
      border-bottom: 1px solid $col-grid01;
    }

    &:hover {
      background-color: $col-grid01;
    }
  }
}

/*---------------------------------------------------------------------------BP1920*/
@media (max-width: 1920px) {
}
/*---------------------------------------------------------------------------BP1600*/
@media (max-width: 1600px) {
}
/*---------------------------------------------------------------------------BP1280*/
@media (max-width: 1280px) {
}
/*---------------------------------------------------------------------------BP1024*/
@media (max-width: 1024px) {
}
/*---------------------------------------------------------------------------BP1023*/
@media (max-width: 1023px) {
}
/*---------------------------------------------------------------------------BP960*/
@media (max-width: 960px) {
}
/*---------------------------------------------------------------------------BP896*/
@media (max-width: 896px) {
}
/*---------------------------------------------------------------------------BP768*/
@media (max-width: 768px) {
}
/*---------------------------------------------------------------------------BP767*/
@media (max-width: 767px) {
}
/*---------------------------------------------------------------------------BP620*/
@media (max-width: 620px) {
}
/*---------------------------------------------------------------------------BP480*/
@media (max-width: 480px) {
}
