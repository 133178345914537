@import "setting";

.condition {
  width: 100%;
  border: 2px solid black;
  border-radius: 20px;

  padding: 1em;
  margin: 1em 0;

  .paragraph {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: smaller;

    .button {
      min-height: 2.7em;
      padding: 0.5em;
      margin: 0 0.5em;
      border: 2px solid black;
      border-radius: 10px;
      cursor: pointer;
      transition: all 0.3s;

      &:hover {
        background-color: $col-ki;
      }

      &.searchButton {
        border-radius: 20px;
        padding: 0.5em 2em;
        background-color: $col-ki;
        transition: all 0.3s;

        &:hover {
          background-color: $col-ki-d;
        }

        &:active {
          transform: translateY(2px);
          transition: transform 0.2s;
        }
      }
    }

    .area {
      height: 2.7em;
      margin: 0 0.5em;
      width: 30em;
      border: 2px solid black;
      border-radius: 10px;

      .areaInput {
        width: 100%;
        padding-top: 0.1em;

        input {
          border-image-width: 0 !important;
          border: none !important;
          padding: 5px !important;
          font-size: smaller;
          font-weight: bold;
        }

        fieldset {
          border: none !important;
        }
      }
    }

    .spacer {
      flex-grow: 1;
    }
  }

  .divider {
    margin: 1em 0;
  }
}
