.innerMap {
  background-color: rgb(242, 243, 244);
  width: 100%;
  height: 100%;

  .fade {
    width: 100%;
    height: 100%;

    #sumora-map {
      width: 100%;
      height: 100%;
    }
  }
}
