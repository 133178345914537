.copyLinkButtonToolTipPopper {
  padding-right: 10px;
}

.copyLinkButton {
  position: fixed !important;
  top: 30px !important;
  right: 68px !important;
  z-index: 9999 !important;
  background-color: #ccc !important;
  width: 30px !important;
  height: 30px !important;
  &:hover {
    background-color: #999 !important;
  }

  .icon {
    img {
      margin-top: -16px;
      height: 20px;
      width: 20px;
    }
  }
}
