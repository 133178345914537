.gm-style-iw-t:has(.sticky-note-info-window) {
  .gm-style-iw-tc {
    display: none;
  }

  .gm-style-iw-chr {
    height: 1.5em;
  }

  .footer {
    display: inline-flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    background-color: whitesmoke;
    font-size: 1.2rem;
    padding: 0.5em 1em;
    border-radius: 0 0 8px 8px;

    .updated-at {
      font-size: 12px;
      font-family: din-2014, zen-maru-gothic, sans-serif;
      font-weight: 600;
      padding: 0;
    }

    .buttons {
      padding: 0;
      display: flex;
    }
  }

  button {
    border-radius: calc(100% / 2) !important;
    border: 2px solid black !important;
    background-color: white !important;
    opacity: 1 !important;

    top: -14px !important;
    right: -14px !important;
    padding: 0 0 0 1em;

    transform: rotate(0deg) scale(1) !important;
    transition: transform 0.3s !important;

    span {
      margin: 10px !important;
    }

    &:hover {
      transform: rotate(180deg) scale(1.2) !important;
      transition: transform 0.3s !important;
    }

    &.sticky-note {
      border: none !important;
      background: none !important;
      color: black;
      transform: none !important;
      transition: none !important;
      cursor: pointer;
      line-height: 1;
      font-size: initial;

      top: unset !important;
      right: unset !important;

      img {
        width: 16px;
        height: auto;
        min-width: 12px;
        vertical-align: bottom;
        transition: 0.2s;

        &:hover {
          transform: scale(1.2);
        }
      }
    }
  }

  .gm-style-iw.gm-style-iw-c {
    overflow: visible;
    padding: 0 !important;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    border: 2px solid black;

    .gm-style-iw-d {
      padding: 0;
      overflow: hidden !important;

      .sticky-note-info-window {
        width: 100%;
        padding: 1em;
        font-family: din-2014, zen-maru-gothic, sans-serif;
        font-size: 1.2em;
        line-height: 1.4;
        font-feature-settings: "palt";
        -webkit-font-feature-settings: "palt";
        -moz-font-feature-settings: "palt";
      }
    }
  }
}
