@import "setting";

.backdrop {
  background-color: rgba(255, 255, 255, 0.75) !important;
}

.dialogPaper {
  position: relative;
  border-radius: 40px !important;
  border: solid 5px black;
  padding: 2em 0 1em 0;
  margin-top: calc(var(--headerHeight) * 1.5) !important;
  overflow-y: visible !important;

  .alert {
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .icon {
    padding: 0px;
  }

  .dialogContent {
    width: clamp(50vw, 80vh, 85vw);
    height: calc(100dvh - var(--headerHeight) - 12rem - 0.5vh);
    overflow-x: hidden !important;
    padding: 20px 5%;

    * {
      font-weight: 500;
    }

    .fieldsWrapper {
      padding-top: 1em;
    }

    .info {
      font-size: 90%;
      border-radius: 20px;
      border: solid 2px black;
    }

    .fieldWrapper {
      margin-top: 0.5em;
      margin-left: 1em;

      .field {
        border: 2px solid black;
        border-radius: 0.5rem;
        padding: 8px 2px 2px 2px;
      }
    }
  }

  .map {
    min-width: 100%;
    padding: 10px;
    max-height: 70dvh;
    height: 500px;

    border: 2px solid black;
    border-radius: 0.5rem;
  }

  .actions {
    height: 3rem;
    display: flex;
    justify-content: center;
    margin-top: 1em;

    .okButton {
      border-radius: 40px;
      border: solid 4px black;
      background-color: $col-ki;
      color: black;
      font-weight: 700;
      width: 120px;

      &:hover {
        background-color: $col-ki-d;
      }
    }

    .cancelButton {
      border-radius: 40px;
      border: solid 4px black;
      background-color: white;
      color: black;
      font-weight: 700;
      width: 120px;

      &:hover {
        background-color: $col-hai-l;
      }
    }
  }
}
