@import "setting";

.progressBackdrop {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1500;

  .container {
    display: block;
    outline: none;

    .spinner {
      text-align: center;
    }

    .message {
      font-size: 1.5em;
      text-align: center;
      color: white;
      text-shadow: black 1px 0 10px;
      width: 60dvw;
    }

    .buttonWrapper {
      text-align: center;

      .button {
        border-radius: 40px;
        border: solid 4px black;
        background-color: white;
        color: black;
        font-weight: 700;

        &:hover {
          background-color: $col-ki-d;
        }

        &.active {
          background-color: $col-ki;
        }

        &.deactive {
          background-color: white;
        }
      }
    }
  }
}

@media screen and (max-width: 720px) {
  .progressBackdrop {
    .container {
      .message {
        width: 90dvw;
      }
    }
  }
}
