@import "setting";

.cardHeader {
  height: var(--headerHeight);
  padding: 0.8em 0 0.5em 0.5em;
  background-color: $col-ki;
  color: black;
  font-weight: 700;
}

.iconButton {
  color: black;
  cursor: pointer;
  margin: 8px 10px 0 0;
  transform: scale(0.8) translate(0, -0.8rem);
}
