/* Scss Document */

////////////////////////////////*■■■SCSS形式で作成■■■*////////////////////////////////

@import "setting";

.menu_header {
  .left {
    figure {
      &.logo {
        &.visible {
          display: block;
        }

        &.hidden {
          display: none;
        }
      }
    }
  }
}

.menu_header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  padding: 10px 20px;
  height: 50px;
  background-color: $col-w;
  display: grid;
  grid-template-columns: minmax(150px, auto) auto;
  z-index: 2500;

  .left {
    display: inline-flex;
    align-items: center;

    figure {
      margin: 0;
      padding: 0;
      overflow: hidden;

      &.logo {
        img {
          cursor: pointer;
          width: auto;
          height: 35px;
        }
      }
    }

    .trial {
      padding-left: 2em;
      font-size: 80%;
      font-weight: bold;

      @media (max-width: 720px) {
        display: none;
      }
    }
  }

  .right {
    display: grid;
    justify-content: flex-end;
  }
}

/*---------------------------------------------------------------------------BP1920*/
@media (max-width: 1920px) {
}
/*---------------------------------------------------------------------------BP1600*/
@media (max-width: 1600px) {
}
/*---------------------------------------------------------------------------BP1280*/
@media (max-width: 1280px) {
}
/*---------------------------------------------------------------------------BP1024*/
@media (max-width: 1024px) {
}
/*---------------------------------------------------------------------------BP1023*/
@media (max-width: 1023px) {
}
/*---------------------------------------------------------------------------BP960*/
@media (max-width: 960px) {
}
/*---------------------------------------------------------------------------BP896*/
@media (max-width: 896px) {
}
/*---------------------------------------------------------------------------BP768*/
@media (max-width: 768px) {
}
/*---------------------------------------------------------------------------BP767*/
@media (max-width: 767px) {
}
/*---------------------------------------------------------------------------BP620*/
@media (max-width: 620px) {
}
/*---------------------------------------------------------------------------BP480*/
@media (max-width: 480px) {
}
