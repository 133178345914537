/* Scss Document */

////////////////////////////////*■■■SCSS形式で作成■■■*////////////////////////////////

@import "setting";

/*サークルポジショニング*/
.select_circle {
  position: absolute;
  top: 45px;
}

/*サークル*/
.select_circle {
  overflow: visible;
  margin: 0;
  padding: 0;
  outline: none;
  display: block;
  opacity: 0;
  transform: scale(0);

  &::before {
    content: "";
    position: absolute;
    left: -12px;
    top: -56px;
    width: 62px;
    height: 62px;
    transform-origin: 50% 50%;
    display: inline-block;
    background-size: 100% 100%;
  }

  &.type_ground::before {
    background-image: url("../images/select_circle_ground.png");
  }
  &.type_block::before {
    background-image: url("../images/select_circle_block.png");
  }
  &.type_new::before {
    background-image: url("../images/select_circle_new.png");
  }
  &.type_used::before {
    background-image: url("../images/select_circle_used.png");
  }
  &.type_apartment::before {
    background-image: url("../images/select_circle_apartment.png");
  }
  &.type_business::before {
    background-image: url("../images/select_circle_business.png");
  }
  &.type_mix::before {
    background-image: url("../images/select_circle_mix.png");
  }
  &.type_saled::before {
    background-image: url("../images/select_circle_saled.png");
  }
}
.select_circle.on {
  animation: select_circle_in 2.1s ease-out 0s 1 forwards;
}
.select_circle.on::before {
  animation: select_circle_rotate 3.6s linear 0s infinite forwards;
}
@keyframes select_circle_in {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  50% {
    opacity: 0.5;
    transform: scale(1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes select_circle_rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
/*---------------------------------------------------------------------------BP1920*/
@media (max-width: 1920px) {
}
/*---------------------------------------------------------------------------BP1600*/
@media (max-width: 1600px) {
}
/*---------------------------------------------------------------------------BP1280*/
@media (max-width: 1280px) {
}
/*---------------------------------------------------------------------------BP1024*/
@media (max-width: 1024px) {
}
/*---------------------------------------------------------------------------BP1023*/
@media (max-width: 1023px) {
}
/*---------------------------------------------------------------------------BP960*/
@media (max-width: 960px) {
}
/*---------------------------------------------------------------------------BP896*/
@media (max-width: 896px) {
}
/*---------------------------------------------------------------------------BP768*/
@media (max-width: 768px) {
}
/*---------------------------------------------------------------------------BP767*/
@media (max-width: 767px) {
}
/*---------------------------------------------------------------------------BP620*/
@media (max-width: 620px) {
}
/*---------------------------------------------------------------------------BP480*/
@media (max-width: 480px) {
}
