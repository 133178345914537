.label {
  color: black;
  margin-bottom: 0.6em;
}

@import "setting";
@import "../../../../assets/scss/dialogs.scss";

.wrapper {
  margin-left: 1em;

  display: flex;
  flex-wrap: wrap;

  * {
    font-weight: 700 !important;
  }
}

.btn_switch01 {
  margin: 0 0.5rem 0.5rem 0 !important;
}
