.markDown {
  h2 {
    color: black;
  }

  h3 {
    margin-left: 0.5em;
  }

  h4 {
    margin-left: 1em;
  }

  h5 {
    margin-left: 3em;
  }

  ol,
  ul {
    margin-left: 2.5em;
    li {
      margin-top: 0.5em;
      margin-bottom: 0.5em;
    }
  }
}
