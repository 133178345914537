@import "setting";

.headerBar {
  padding: 0;
  margin: 0;
  font-size: 80%;
  line-height: 0.8rem;
  text-align: right;

  .filtering {
    width: 2em;
    height: 2em;
    border: 1.5px solid black;
    border-radius: 50%;
    background-color: $col-ki;
    transition: background-color 0.3s;

    &:hover {
      background-color: $col-ki-d;
      cursor: pointer;
    }

    @media (max-width: 620px) {
      display: none;
    }
  }

  .land,
  .block,
  .new,
  .used,
  .apart,
  .business {
    border-width: 1.5px;
    border-style: solid;
    border-radius: 30%;
    display: inline-flex;
    align-items: center;

    svg {
      width: 1.5em;
      height: 1.5em;
      fill: currentColor;
    }

    @media (max-width: 620px) {
      display: none;
    }
  }

  .land {
    color: $col-bknk-ground;
    border-color: $col-bknk-ground;
  }

  .block {
    color: $col-bknk-block;
    border-color: $col-bknk-block;
  }

  .new {
    color: $col-bknk-new;
    border-color: $col-bknk-new;
  }

  .used {
    color: $col-bknk-used;
    border-color: $col-bknk-used;
  }

  .apart {
    color: $col-bknk-apartment;
    border-color: $col-bknk-apartment;
  }

  .business {
    color: $col-bknk-business;
    border-color: $col-bknk-business;
  }

  .userInfo {
    cursor: pointer;
    padding: 0.2em 0.5em;
    border-radius: 5px;
    transition: background-color 0.3s;

    &:hover {
      background-color: $col-ki-l;
    }
  }
}

.userInfoMenu {
  z-index: 5000 !important;

  .menu {
    border-radius: 10px;
    border: 2px solid black;
  }

  .menuItemText {
    font-size: 90%;
    font-weight: 600;
  }
}
