@import "setting";

.backdrop {
  background-color: rgba(255, 255, 255, 0.75) !important;
}

.dialogPaper {
  position: relative;
  border-radius: 40px !important;
  border: solid 4px black;
  padding: 2em 0 1em 0;
  overflow-y: visible !important;
  width: 700px !important;
  max-width: 90vw !important;

  .paragraph {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    font-weight: bold;

    .button {
      padding: 0.5em;
      margin: 0.5em;
      border-radius: 20px;
      cursor: pointer;
      background-color: $col-hai-l;
      border: 2px solid transparent;
      word-wrap: none;
      word-break: keep-all;
      transition: all 0.2s;

      &.on {
        background-color: $col-ki;
      }

      &.off {
        background-color: $col-hai-l;
      }

      &:hover {
        border: 2px solid black;
      }

      &:active {
        transform: translateY(3px);
        transition: transform 0.1s;
      }
    }
  }

  .actions {
    display: flex;
    justify-content: center;
    margin-top: 1em;

    .okButton {
      border-radius: 40px;
      border: solid 4px black;
      background-color: $col-ki;
      color: black;
      font-weight: 700;
      width: 120px;

      &:hover {
        background-color: $col-ki-d;
      }

      &:disabled {
        background-color: $col-hai-l;
        border-color: $col-hai;
        color: $col-hai;
      }
    }

    .cancelButton {
      border-radius: 40px;
      border: solid 4px black;
      background-color: white;
      color: black;
      font-weight: 700;
      width: 120px;

      &:hover {
        background-color: $col-hai-l;
      }
    }
  }
}
