@import "setting";

:root {
  --condition-selector-height: 150px;
  --multi-print-height: 50px;
}

.estatesList {
  height: calc(
    100dvh - var(--headerHeight) - var(--condition-selector-height) - 100px
  );

  border: 2px solid black;
  border-radius: 20px;
  padding: 0.5em;

  transition: all 0.3s;

  .dataGrid {
    height: calc(100% - var(--multi-print-height)) !important;
  }

  .progress {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
}

.idWrapper,
.selectWrapper {
  display: flex;
  align-items: center;
  height: 100%;

  .id {
    min-width: 5em !important;
    padding: 0 0.5em;
  }
}

.selectWrapper {
  justify-content: center;

  .selectCheckbox {
    padding: 0;

    svg {
      fill: black !important;
    }

    &.disabled {
      svg {
        fill: lightgrey !important;
      }
    }
  }
}

.operationButton {
  width: 1.2em !important;
  height: 1.2em !important;

  div {
    &.disabled {
      color: lightgrey !important;
    }

    &.reportable {
      color: darkorange !important;
    }

    &.editable {
      color: blue !important;
    }

    &.deletable {
      color: red !important;
    }

    &.referable {
      color: lightseagreen !important;
    }

    &.printable {
      color: darkslategray !important;
    }

    svg {
      width: 0.8em;
      height: 0.8em;

      * {
        fill: currentColor !important;
      }
    }
  }
}

.multiPrint {
  height: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: var(--multi-print-height);

  font-weight: bold;
  font-size: smaller;

  .button {
    min-height: 2.7em;
    padding: 0.5em 2em;
    margin: 1em;
    border: 2px solid black;
    border-radius: 20px;
    background-color: $col-ki;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      background-color: $col-ki-d;
    }

    &:active {
      transform: translateY(2px);
      transition: transform 0.2s;
    }

    &.clear {
      background-color: white;

      &:hover {
        background-color: $col-ki;
      }
    }

    &.disabled {
      border-color: lightgray;
      background-color: white;
      color: lightgrey;
      cursor: default;

      &:hover {
        background-color: white;
      }

      &:active {
        transform: none;
      }
    }
  }
}
