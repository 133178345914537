/* Scss Document */

////////////////////////////////*■■■SCSS形式で作成■■■*////////////////////////////////

@import "setting";

/********************アプデアイコン（新登録・値動き）********************/

/*▼デザイン*/
$size-icud1: 15px;
$size-icud2: 28px;

.icon_ud {
  position: absolute;
  z-index: 100;
  filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.25));
  opacity: 0.8;

  div {
    position: absolute;

    &.new,
    &.price {
      left: 29px;
      top: -49px;

      width: $size-icud1;
      height: $size-icud1;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;

      &::before {
        content: "";
        display: block;
        width: $size-icud1;
        height: $size-icud1;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }
    }

    &.new {
      &::before {
        background-image: url("../images/ic_ud_new.svg");
      }
    }

    &.price {
      &::before {
        background-image: url("../images/ic_ud_price.svg");
      }
    }

    &.new.price {
      left: -4.5px;
      top: -49px;

      width: $size-icud2;

      &::before {
        display: block;
        width: $size-icud2;
        background-image: url("../images/ic_ud_both.svg");
      }
    }
  }
}


/*---------------------------------------------------------------------------BP1920*/
@media (max-width: 1920px) {
}
/*---------------------------------------------------------------------------BP1600*/
@media (max-width: 1600px) {
}
/*---------------------------------------------------------------------------BP1280*/
@media (max-width: 1280px) {
}
/*---------------------------------------------------------------------------BP1024*/
@media (max-width: 1024px) {
}
/*---------------------------------------------------------------------------BP1023*/
@media (max-width: 1023px) {
}
/*---------------------------------------------------------------------------BP960*/
@media (max-width: 960px) {
}
/*---------------------------------------------------------------------------BP896*/
@media (max-width: 896px) {
}
/*---------------------------------------------------------------------------BP768*/
@media (max-width: 768px) {
}
/*---------------------------------------------------------------------------BP767*/
@media (max-width: 767px) {
}
/*---------------------------------------------------------------------------BP620*/
@media (max-width: 620px) {
}
/*---------------------------------------------------------------------------BP480*/
@media (max-width: 480px) {
}
