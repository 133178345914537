@import "setting";

.dialogHeader {
  position: absolute;
  height: 64px;
  width: 100%;
  top: -32px;

  .icon {
    display: flex;
    justify-content: center;

    svg,
    div {
      background-color: white;
      border: 5px solid black;
      border-radius: 50%;
      width: 64px;
      height: 64px;
    }

    div {
      padding: 5px;
    }
  }
}
