@import "setting";

.top {
  width: 100%;
  height: 100px;
  display: inline-block;
  align-content: center;

  .logo {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 40%;
      height: calc(80% / 100 * 71);
    }
  }

  .poweredBy {
    margin-top: 1em;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2em;
    font-weight: bold;
    color: black;
  }
}

.middle {
  width: 100%;
  display: inline-block;
  align-content: center;

  .info {
    padding: 1em 0.5em;

    .title {
      margin: 0.5em;
      font-size: 1.2em;
      font-weight: bold;
      color: black;
    }

    .content {
      width: 100%;
      height: 20dvh;
      border-radius: 20px;
      background-color: rgba(255, 255, 255, 0.6);
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
      padding: 1em 1em 1em 2em;
      overflow-y: auto;
      overflow-x: hidden;

      ul {
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
          position: relative;
          margin: 0.5em 0;
          font-weight: bold;
          color: black;
          cursor: pointer;

          &::before {
            position: absolute;
            top: calc(50% - 0.1em);
            content: "";
            display: inline-block;
            width: 0.5em;
            height: 0.2em;
            margin-left: -0.5em;
            background-color: $col-ki-d;
          }

          a {
            margin-left: 0.5em;
            padding: 5px 10px;
            text-decoration: none;
            color: black;
            transition: background-color 0.5s;

            &:visited {
              color: black;
            }

            &:hover {
              background-color: $col-ki;
              border-radius: 10px;
            }

            .subject {
              margin-left: 1em;
            }
          }
        }
      }
    }
  }

  .menu {
    padding: 0.5em;

    .menuButton {
      display: flex;
      border-radius: 20px;
      background-color: rgba(255, 255, 255, 0.6);
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
      width: 100%;
      height: 100px;
      cursor: pointer;
      transition: transform 0.2s;

      &:hover {
        transform: scale(1.1);
      }

      .menuBadge {
        display: flex;
        width: 100%;
        height: 100%;
      }

      .menuIcon {
        width: 25%;
        display: flex;
        justify-content: center;
        align-items: center;

        .menuIconSvg {
          width: 80%;
          height: 80%;
          fill: $col-cha;
        }
      }

      .caption {
        width: 75%;
        padding: 2em;
        display: flex;
        align-items: center;
        font-weight: bold;
        color: black;
      }
    }
  }
}

.bottom {
  width: 100%;
  display: inline-block;
  align-content: center;

  .links {
    padding: 0.5em;

    ul {
      display: inline-flex;
      flex-wrap: wrap;
      list-style-type: none;
      padding: 0;
      margin: 0;

      li {
        position: relative;
        margin: 0.5em 0;
        font-weight: bold;
        color: black;
        cursor: pointer;

        a {
          margin-left: 0.5em;
          padding: 5px 10px;
          text-decoration: none;
          color: black;
          transition: background-color 0.5s;

          &:visited {
            color: black;
          }

          &:hover {
            background-color: $col-ki;
            border-radius: 10px;
          }
        }
      }
    }
  }
}
