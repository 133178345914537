/* Scss Document */

////////////////////////////////*■■■SCSS形式で作成■■■*////////////////////////////////

/*▼▼▼bkn_komado▼▼▼*/
$col-bknk-ground: rgba(140, 200, 115, 1);
$col-bknk-ground-a: rgba(140, 200, 115, 0.15);
$col-bknk-ground-d: rgba(110, 180, 90, 1);
$col-bknk-block: rgba(180, 200, 80, 1);
$col-bknk-block-a: rgba(180, 200, 80, 0.15);
$col-bknk-block-d: rgba(154, 174, 54, 1);
$col-bknk-new: rgba(240, 170, 170, 1);
$col-bknk-new-a: rgba(240, 170, 170, 0.15);
$col-bknk-new-d: rgba(240, 130, 130, 1);
$col-bknk-used: rgba(245, 180, 110, 1);
$col-bknk-used-a: rgba(245, 180, 110, 0.15);
$col-bknk-used-d: rgba(245, 150, 60, 1);
$col-bknk-apartment: rgba(140, 210, 240, 1);
$col-bknk-apartment-a: rgba(140, 210, 240, 0.15);
$col-bknk-apartment-a5: rgba(140, 210, 240, 0.5);
$col-bknk-apartment-d: rgba(100, 180, 230, 1);
$col-bknk-business: rgba(206, 179, 223, 1);
$col-bknk-business-a: rgba(164, 128, 193, 0.15);
$col-bknk-business-d: rgba(164, 128, 193, 1);
$col-bknk-sold: rgba(185, 190, 195, 1);

@mixin bknk {
  position: relative;
  width: 280px;
  border-radius: 30px;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.15);
}
@mixin bknk_handle {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 110px;
  height: 50px;
  z-index: 200;
}
@mixin bknk_handle2 {
  position: absolute;
  top: -5px;
  left: 0;
  width: calc(280px - 24px);
  height: 20px;
  z-index: 200;
}
@mixin bknk_handle3 {
  position: absolute;
  top: 35px;
  left: 0;
  width: 280px;
  height: 20px;
  z-index: 200;
}
@mixin bknk_handle4 {
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 280px;
  height: 32px;
  z-index: 200;
}
@mixin bknk_handle_hov {
  cursor: grab;
}
@mixin bknk_handle_act {
  cursor: grabbing;
}
@mixin bknk_close {
  position: absolute;
  z-index: 100;
  line-height: 1;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 1000px;
  top: -0.75em;
  right: -1em;
  width: 3em;
  height: 3em;
  overflow: visible;
  transition: 0.2s;
  cursor: pointer;
}
@mixin bknk_close_hov {
  transform: rotate(180deg) scale(1.05);
}
@mixin bknk_pagenation {
  text-align: right;
  font-size: 0.875em;
  font-family: $f-dingot;
  position: absolute;
  z-index: 90;
  top: 17px;
  right: 2.75em;
  @include ilfx;
  @include jc-e;
  color: white;
  line-height: 1;
}
@mixin bknk_pagenation_prev_next {
  width: 1em;
  aspect-ratio: 1 / 1;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: 0.2s;
  cursor: pointer;
}
@mixin bknk_pagenation_prev {
  background-image: url("./assets/icons/estate_info/btn_prev.svg");
}
@mixin bknk_pagenation_prev_hov {
  background-image: url("./assets/icons/estate_info/btn_prev_hov.svg");
}
@mixin bknk_pagenation_next {
  background-image: url("./assets/icons/estate_info/btn_next.svg");
}
@mixin bknk_pagenation_next_hov {
  background-image: url("./assets/icons/estate_info/btn_next_hov.svg");
}
@mixin bknk_pagenation_page {
  margin: 0 4px;
}
@mixin bknk_main {
}
@mixin bknk_main_h1 {
  position: absolute;
  z-index: 90;
  top: 17px;
  left: 2em;
  font-size: 0.875em;
  font-weight: normal;
  color: white;
  margin: 0;
  padding: 0;
  font-family: $f-dingot;
  line-height: 1;
}
@mixin bknk_main_h2 {
  width: 110px;
  margin: 0.35em auto 0;
  aspect-ratio: 100 / 33;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: contain;
  filter: drop-shadow(0px 1px 0px white);
}
@mixin bknk_main_article {
  background-color: white;
  padding: 1.25em 1.5em 0.625em;
  border-radius: 30px;
  position: relative;
}
@mixin bknk_main_price_size {
  @include fx;
  @include ai-c;
  @include jc-sb;
  font-family: $f-dingot;
  height: 40px;
}
@mixin bknk_main_price_size_current_area {
  line-height: 1;
}
@mixin bknk_main_price_size_current_area_big {
  font-weight: 600;
  font-size: 1.5em;
}
@mixin bknk_main_price_size_current_area_small {
  font-weight: 600;
  font-size: 0.75em;
  display: inline-block;
  margin-left: 3px;
}
@mixin bknk_main_price_size_update_unit {
  text-align: right;
  line-height: 1;
  @include fx;
  @include jc-e;
  @include ai-c;
}
@mixin bknk_main_price_size_update_unit_small {
  font-weight: 600;
  font-size: 10px;
  display: inline-block;
  margin-left: 2px;
}
@mixin bknk_main_price_size_update_unit_bef {
  content: "";
  display: inline-block;
  width: 1.125em;
  aspect-ratio: 1 / 1;
  margin: 0 8px 2px 0;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
@mixin bknk_main_price_update_none_bef {
  background-image: url("./assets/icons/estate_info/ic_price_none.svg");
}
@mixin bknk_main_price_update_down_bef {
  background-image: url("./assets/icons/estate_info/ic_price_down.svg");
  cursor: pointer;
}
@mixin bknk_main_price_update_up_bef {
  background-image: url("./assets/icons/estate_info/ic_price_up.svg");
  cursor: pointer;
}
@mixin bknk_main_size_unit_bef {
  background-image: url("./assets/icons/estate_info/ic_unit.svg");
}
@mixin bknk_main_date {
  font-weight: normal;
  font-size: 0.75em;
  letter-spacing: 0.075em;
}
@mixin bknk_main_last {
  font-weight: bold;
  font-size: 0.875em;
}
@mixin bknk_main_unitprice {
  font-weight: bold;
  font-size: 1em;
}
@mixin bknk_main_tag {
  margin: 0.5em 0 0 0;
}
@mixin bknk_main_tag_ul {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
}
@mixin bknk_main_tag_ul_li {
  line-height: 1;
  margin: 3px;
  padding: 0.35em 0.5em;
  font-size: 0.875em;
  font-weight: bold;
  display: inline-block;
  border-radius: 7px;
  font-family: $f-tk;
}
@mixin bknk_main_place {
  font-size: 0.75em;
  text-align: center;
  line-height: 1.35;
  margin: 1em 0 0 0;
}
@mixin bknk_main_place_add_acc {
  font-family: $f-tk;
}
@mixin bknk_main_tools {
  margin: 1em 0 0 0;
}
@mixin bknk_main_tools_ul {
  list-style: none;
  margin: 0;
  padding: 0;
  @include fx;
  @include jc-sb;
  position: relative;
}
@mixin bknk_main_tools_ul_li {
  margin: 0;
  padding: 2px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 100px;
  line-height: 1;
  z-index: 90;
}
@mixin bknk_main_tools_ul_li_img {
  width: 16px;
  height: auto;
  min-width: 12px;
  vertical-align: bottom;
  transition: 0.2s;
}
@mixin bknk_main_tools_ul_li_hov {
  z-index: 95;
}
@mixin bknk_main_tools_ul_li_hov_img {
  transform: scale(1.75);
}
@mixin bknk_main_info {
  text-align: center;
  font-size: 12px;
  line-height: 1;
  margin: 1.5em 0 0 0;
}
@mixin bknk_main_info_entry_view {
  font-family: $f-dingot;
  display: inline-block;
  letter-spacing: 0.035em;
}
@mixin bknk_main_info_entry_view_bef {
  content: "";
  display: inline-block;
  width: 1em;
  aspect-ratio: 1 / 1;
  margin: 0 5px 0 0;
  vertical-align: -0.1em;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
@mixin bknk_main_info_entry {
  margin-right: 1.5em;
}
@mixin bknk_main_info_entry_bef {
  background-image: url("./assets/icons/estate_info/ic_entry.svg");
}
@mixin bknk_main_info_view_bef {
  background-image: url("./assets/icons/estate_info/ic_view.svg");
}
@mixin bknk_main_layout {
  text-align: center;
  font-weight: 600;
  font-size: 1em;
  font-family: $f-dingot;
  line-height: 1.25;
  padding: 0.6em 0 0.5em;
}
@mixin bknk_main_about {
  @include fx;
  @include jc-sb;
  padding: 0.5em 0;
}
@mixin bknk_main_about_div {
  @include fx;
  @include fx-c;
  @include jc-sb;
  @include ai-c;
  text-align: center;
  width: calc(100% / 2);
}
@mixin bknk_main_about_div_img {
  height: 18px;
  width: auto;
}
@mixin bknk_main_about_div_tit_dtl_big_small {
  font-family: $f-dingot;
  line-height: 1;
}
@mixin bknk_main_about_div_tit {
  font-weight: normal;
  width: 100%;
  margin-bottom: 3px;
}
@mixin bknk_main_about_div_dtl_big {
  font-size: 0.9375em;
  font-weight: normal;
  letter-spacing: 0.035em;
}
@mixin bknk_main_about_div_dtl_small {
  font-size: 0.75em;
  font-weight: 600;
  margin-left: 2px;
}
@mixin bknk_main_about_yo_dtl_big {
  font-size: 1.25em;
}
@mixin bknk_main_about_yo_chiku {
  margin: 0 2px 0 0;
}
@mixin bknk_main_history_dtl_a {
  display: inline-block;
  transition: 0.2s;
  background-color: white;
  border-radius: 1000px;
  border: 2px solid $col-tx;
  padding: 0.35em 1em 0.25em;
  text-decoration: none;
  color: black !important;
  font-size: 0.875em;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}
@mixin bknk_main_history_dtl_a_hov {
  background-color: $col-bknk-apartment-a5;
}
@mixin bknk_main_name {
  background-image: url("./assets/icons/estate_info/deco_apartment.svg");
  background-size: auto 1.85em;
  background-position: bottom 0 right 7.5%;
  background-repeat: no-repeat;
  line-height: 1.15;
  font-weight: bold;
  width: 100%;
  text-align: left;
  padding: 0.75em 1em 0.65em;
  border-radius: 10px;
  border: 2px solid $col-tx;
  color: $col-tx;
  background-color: whitesmoke;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
}
@mixin bknk_main_roomprice {
  position: relative;
  scrollbar-width: none;
  border-top: 1px solid #dadada;
  border-bottom: 1px solid #dadada;
  margin-top: 0.5em;
  max-height: /*163*/ 35px;
  overflow-y: auto;
  transition: 0.2s;
}
@mixin bknk_main_roomprice_scrollbar {
  display: none;
}
@mixin bknk_main_roomprice_high {
  max-height: 300px !important;
  transition: 0.4s;
}
@mixin bknk_main_roomprice_high_dl_tit_dd_img {
  transform: rotate(180deg);
}
@mixin bknk_main_roomprice_high_dl_tit_hov_dd_img {
  transform: rotate(540deg);
}
@mixin bknk_main_roomprice_dl {
  @include fx;
  @include jc-sb;
  margin: 0 0 0.75em 0;
  padding: 0;
}
@mixin bknk_main_roomprice_dl {
  @include fx;
  @include jc-sb;
  margin: 0 0 0.5em 0;
  padding: 0;
}
@mixin bknk_main_roomprice_dl_last {
  margin-bottom: 0.75em;
}
@mixin bknk_main_roomprice_dl_dtddspan {
  border-radius: 12px;
  margin: 0;
  padding: 0;
  font-family: $f-dingot;
  line-height: 1;
}
@mixin bknk_main_roomprice_dl_dt {
  width: 2em;
  color: white;
}
@mixin bknk_main_roomprice_dl_dt_span {
  font-weight: 700;
  font-size: 0.875em;
  @include fx;
  @include fx-c;
  @include ai-c;
  @include jc-c;
  width: 100%;
  aspect-ratio: 1 / 1;
  background-color: rgba(150, 150, 150, 1);
  background-image: url("./assets/icons/estate_info/deco_step.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top left;
}
@mixin bknk_main_roomprice_dl_dd {
  @include fx;
  @include fx-w;
  @include ai-c;
  padding: 0.25em;
  width: calc(100% - 2.35em);
  background-color: rgba(245, 245, 245, 1);
}
@mixin bknk_main_roomprice_dl_dd_span {
  font-weight: 600;
  font-size: 0.875em;
  padding: 0.25em 0.45em;
  transition: 0.4s;
}
@mixin bknk_main_roomprice_dl_dd_span_hov {
  background-color: $col-bknk-apartment-a5;
}
@mixin bknk_main_roomprice_dl_dd {
  @include fx;
  @include fx-c;
  @include jc-c;
  width: calc(100% - 2.35em);
}
@mixin bknk_main_roomprice_dl_dd_btnroomprice {
  margin-top: 5px;
  height: 2em;
  width: 100%;
  transition: 0.3s;
  background-color: whitesmoke;
  @include fx;
  @include ai-c;
  padding: 0 0.75em;
  border: 2px solid rgba(0, 0, 0, 0);
  border-radius: 10px;
}
@mixin bknk_main_roomprice_dl_dd_btnroomprice_dtl {
  font-weight: 600;
  font-size: 0.875em;
  margin-right: 1em;
}
@mixin bknk_main_roomprice_dl_dd_btnroomprice_dtl1 {
  width: 3em;
}
@mixin bknk_main_roomprice_dl_dd_btnroomprice_dtl2 {
  width: calc(100% - 3em - 3em);
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
@mixin bknk_main_roomprice_dl_dd_btnroomprice_aft {
  content: "";
  display: block;
  transition: 0.6s;
  width: 0.85em;
  height: 0.85em;
  background-image: url("./assets/icons/estate_info/btn_right_bk.svg");
  opacity: 0.1;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
@mixin bknk_main_roomprice_dl_dd_btnroomprice_hov {
  cursor: pointer;
  background-color: white;
  border: 2px solid $col-tx;
}
@mixin bknk_main_roomprice_dl_dd_btnroomprice_hov_aft {
  opacity: 1;
  transform: rotateX(180deg);
}
@mixin bknk_main_roomprice_dl_dd_btnroomprice_first {
  margin-top: 0;
}
@mixin bknk_main_roomprice_dl_dd_span {
  /*font-weight: 600; font-size: 0.875em; padding: 0.25em 0.45em; transition: 0.4s;*/
}
@mixin bknk_main_roomprice_dl_dd_span_hov {
  /*background-color: $col-bknk-apartment-a5;*/
}
@mixin bknk_main_roomprice_dl_tit {
  padding: 0.35em 0;
  transition: 0.2s;
}
@mixin bknk_main_roomprice_dl_tit_dtddspan {
  background: none;
}
@mixin bknk_main_roomprice_dl_tit_dt {
  padding: 0 4px;
}
@mixin bknk_main_roomprice_dl_tit_dd {
  font-weight: 700;
  padding: 0;
  margin-left: 1em;
  @include fx;
  @include jc-sb;
  @include ai-c;
}
@mixin bknk_main_roomprice_dl_tit_dd {
  font-weight: 700;
  padding: 0;
  margin-left: 1em;
  @include fx;
  @include fx-r;
  @include jc-sb;
  @include ai-c;
}
@mixin bknk_main_roomprice_dl_tit_dd_span {
  padding: 0;
}
@mixin bknk_main_roomprice_dl_tit_dd_img {
  width: 1em;
  height: auto;
  margin-right: 4px;
  transition: 0.2s;
}
@mixin bknk_main_roomprice_dl_tit_hov {
  background-color: whitesmoke;
  cursor: pointer;
}
@mixin bknk_main_roomprice_dl_tit_hov_dd_img {
  transform: rotate(360deg);
}

/*↓子小窓*/
@mixin bknk_bknkk {
  position: absolute;
  width: 100%;
  transition: 0.2s;
  overflow: hidden;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1em;
  @include fx;
  @include fx-c;
  @include jc-c;
  z-index: 95;
  border-radius: 30px;
  background-color: rgba(255, 255, 255, 0.925);
}
@mixin bknk_bknkk_off {
  height: 0;
  padding: 0 1em;
  opacity: 0;
}
@mixin bknk_bknkk_off_box {
  border-width: 0;
  opacity: 0;
}
@mixin bknk_bknkk_on {
  height: 100%;
  opacity: 1;
}
@mixin bknk_bknkk_on_box {
  opacity: 1;
}
@mixin bknk_bknkk_box {
  position: relative;
  background-color: white;
  border: 4px solid black;
  border-radius: 15px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  transition: 0s;
}
@mixin bknk_bknkk_scroll {
  padding: 0.75em 1em 2em;
  overflow: auto;
  max-height: 100%;
}
@mixin bknk_bknkk_kokomadoclose {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 25px;
  @include fx;
  @include fx-c;
  @include jc-c;
  @include ai-c;
  width: 74px;
  aspect-ratio: 100 / 33;
  margin: auto;
  background-image: url("./assets/icons/estate_info/bg_kokomadoclose.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom center;
  cursor: pointer;
}
@mixin bknk_bknkk_kokomadoclose_img {
  width: 0.75em;
  transition: 0.3s;
  margin-top: 5px;
}
@mixin bknk_bknkk_kokomadoclose_hov_img {
  transform: rotate(180deg);
}
/*↓子小窓の業者専用*/
@mixin bknk_bknkk_contactbox {
  text-align: left;
}
@mixin bknk_bknkk_contactbox_off {
  display: none;
}
@mixin bknk_bknkk_contactbox_on {
  display: block;
}
@mixin bknk_bknkk_contactbox_tit {
  font-weight: bold;
  line-height: 1;
}
@mixin bknk_bknkk_contactbox_tit_bef {
  content: "";
  display: inline-block;
  width: 1.5em;
  vertical-align: -0.15em;
  aspect-ratio: 1 / 1;
  margin-right: 5px;
  background-image: url("./assets/icons/estate_info/tool_contact.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
/*変更*/
@mixin bknk_bknkk_contactbox_ul {
  list-style: none;
  margin: 1.5em 0 1em 0;
  padding: 0;
}
/*変更*/
@mixin bknk_bknkk_contactbox_ul_li {
  margin: 0.75em 0 0 0;
  padding: 0.75em 0.75em 1em;
  line-height: 1.25;
  background-color: whitesmoke;
  border-left: 8px solid black;
  border-radius: 6px;
}
@mixin bknk_bknkk_contactbox_ul_li_lastchild {
}
@mixin bknk_bknkk_contactbox_type {
  text-align: center;
  background-color: #ddd;
  border-radius: 8px;
  font-size: 0.875em;
  font-weight: bold;
  margin-top: 1.5em;
}
@mixin bknk_bknkk_contactbox_ul_li_comname {
  font-size: 0.875em;
  display: block;
  font-weight: bold;
  font-family: $f-tk;
}
@mixin bknk_bknkk_contactbox_ul_li_tel {
  font-size: 1em;
  display: block;
  margin-top: 10px;
  font-family: $f-dingot;
}
@mixin bknk_bknkk_contactbox_ul_li_tel_a {
  text-decoration: none !important;
  color: $col-tx;
}
@mixin bknk_bknkk_contactbox_ul_li_tel_a_bef {
  content: "";
  display: inline-block;
  transform: rotate(135deg);
  transition: 0.5s;
  width: 1.25em;
  height: 1.25em;
  margin-right: 0.35em;
  vertical-align: -0.25em;
  background-image: url("./assets/icons/estate_info/ic_tel.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
@mixin bknk_bknkk_contactbox_ul_li_tel_a_span {
  border-bottom: 1px solid black;
  transition: 0.3s;
}
@mixin bknk_bknkk_contactbox_ul_li_tel_a_hov_span {
  border-bottom: 2px solid black;
}
@mixin bknk_bknkk_contactbox_ul_li_tel_a_hov_bef {
  transform: rotate(360deg);
}
/*↑子小窓の業者専用*/

/*↓子小窓の価格履歴専用*/
@mixin bknk_bknkk_pricehistorybox {
  text-align: left;
}
@mixin bknk_bknkk_pricehistorybox_off {
  display: none;
}
@mixin bknk_bknkk_pricehistorybox_on {
  display: block;
}
@mixin bknk_bknkk_pricehistorybox_tit {
  font-weight: bold;
  line-height: 1;
}
@mixin bknk_bknkk_pricehistorybox_tit_bef {
  content: "";
  display: inline-block;
  width: 1.2em;
  vertical-align: -0.12em;
  aspect-ratio: 1 / 1;
  margin-right: 5px;
  background-image: url("./assets/icons/estate_info/tool_yen.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
@mixin bknk_bknkk_pricehistorybox_ul {
  list-style: none;
  margin: 1.5em 0 1em 0;
  padding: 0;
}
@mixin bknk_bknkk_pricehistorybox_ul_li {
  margin: 0.5em 0 0 0;
  padding: 0.65em;
  line-height: 1;
  background-color: whitesmoke;
  border-left: 8px solid black;
  border-radius: 6px;
  display: grid;
  grid-template-columns: 50% 50%;
}
@mixin bknk_bknkk_pricehistorybox_ul_li_lastchild {
}
@mixin bknk_bknkk_pricehistorybox_ul_li_date {
  font-size: 0.75em;
  font-family: $f-dingot;
  margin-top: 2px;
}
@mixin bknk_bknkk_pricehistorybox_ul_li_price {
  text-align: right;
  font-size: 0.875em;
  font-weight: bold;
  font-family: $f-dingot;
}
/*↑子小窓の価格履歴専用*/
/*↑子小窓*/

/*↓メッセージBOX*/
@mixin bknk_mesbox {
  position: absolute;
  width: 100%;
  overflow: hidden;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1em;
  @include fx;
  @include fx-c;
  @include jc-c;
  z-index: 95;
  border-radius: 30px;
  background-color: rgba(255, 255, 255, 0.925);
}
@mixin bknk_mesbox_off {
  height: 0;
  padding: 0 1em;
  opacity: 0;
}
@mixin bknk_mesbox_off_box {
  border-width: 0;
  opacity: 0;
}
@mixin bknk_mesbox_on {
  height: 100%;
  animation: mesbox 1.4s ease 0s 1 forwards;
}
@mixin bknk_mesbox_on_box {
  animation: mesboxbox 1.4s ease 0s 1 forwards;
}
@mixin bknk_mesbox_box {
  text-align: left;
  @include fx;
  @include ai-c;
  font-size: 0.875em;
  opacity: 0;
  transform: scale(0.75);
  padding: 0.75em 1.75em 0.75em 1em;
  position: relative;
  background-color: #efefef;
  border: 3px solid white;
  border-radius: 1000px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  transition: 0.2s;
  line-height: 1.35;
}
@mixin bknk_mesbox_box_bef {
  content: "";
  display: inline-block;
  width: 2em;
  aspect-ratio: 1/1;
  background-image: url("./assets/icons/estate_info/ic_messagebox.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 0.5em;
}
@mixin anime_mesbox1 {
  opacity: 1;
}
@mixin anime_mesbox2 {
  opacity: 1;
}
@mixin anime_mesbox3 {
  opacity: 0;
}
@mixin anime_mesboxbox1 {
  opacity: 0;
  transform: scale(0.75);
}
@mixin anime_mesboxbox2 {
  opacity: 1;
  transform: scale(1);
}
@mixin anime_mesboxbox3 {
  opacity: 1;
  transform: scale(1);
}
@mixin anime_mesboxbox4 {
  opacity: 1;
  transform: scale(0.75);
}
/*↑メッセージBOX*/

/*↓ふきだし*/
@mixin bknk_fkds {
  position: relative;
}
@mixin bknk_fkds_fkdsbox {
  position: absolute;
  width: auto;
  height: 0;
  opacity: 0;
  text-align: center;
  transition: 0.2s;
  top: -24px;
  left: 50%;
  transform: translateX(-50%);
}
@mixin bknk_fkds_fkdsbox_span {
  white-space: nowrap;
  display: inline-block;
  background-color: white;
  color: $col-tx;
  border-radius: 6px;
  border: 1px solid #dadada;
  border-bottom-width: 2px;
  line-height: 1;
  padding: 0.5em;
  font-size: 0.875em;
  font-weight: normal;
}
@mixin bknk_fkds_hov_fkdsbox {
  height: auto;
  opacity: 1;
  top: -36px;
}
@mixin bknk_main_tools_ul_li_fkds_hov_fkdsbox {
  top: -42px;
}
/*↑ふきだし*/

/*↓sold　成約済み*/
@mixin bknk_sold {
  background-color: $col-bknk-sold !important;
  background: linear-gradient(
    180deg,
    $col-bknk-sold 0%,
    $col-bknk-sold 30%,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 1) 100%
  ) !important;
}
@mixin bknk_sold_close {
  background-image: url("./assets/icons/estate_info/btn_bknk_close_sold.svg") !important;
}
/*↑sold　成約済み*/

/*↓ground　土地*/
@mixin bknk_ground {
  background-color: $col-bknk-ground;
  background: linear-gradient(
    180deg,
    $col-bknk-ground 0%,
    $col-bknk-ground 30%,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 1) 100%
  );
}
@mixin bknk_ground_close {
  background-image: url("./assets/icons/estate_info/btn_bknk_close_ground.svg");
}
@mixin bknk_ground_kokomado_contactbox_ul_li {
  border-color: $col-bknk-ground;
}
@mixin bknk_ground_kokomado_pricehistorybox_ul_li {
  border-color: $col-bknk-ground;
}
@mixin bknk_main_ground_h2 {
  background-image: url("./assets/icons/estate_info/bknk_tit_ground.svg");
}
@mixin bknk_main_ground_price_size {
  height: 40px;
}
@mixin bknk_main_ground_price {
  border-bottom: 1px solid #dadada;
}
@mixin bknk_main_ground_tag_ul_li {
  background-color: $col-bknk-ground-a;
  color: $col-bknk-ground-d;
}
/*↑ground　土地*/

/*↓block　分譲地*/
@mixin bknk_block {
  background-color: $col-bknk-block;
  background: linear-gradient(
    180deg,
    $col-bknk-block 0%,
    $col-bknk-block 30%,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 1) 100%
  );
}
@mixin bknk_block_close {
  background-image: url("./assets/icons/estate_info/btn_bknk_close_block.svg");
}
@mixin bknk_block_kokomado_contactbox_ul_li {
  border-color: $col-bknk-block;
}
@mixin bknk_block_kokomado_pricehistorybox_ul_li {
  border-color: $col-bknk-block;
}
@mixin bknk_main_block_h2 {
  background-image: url("./assets/icons/estate_info/bknk_tit_block.svg");
}
@mixin bknk_main_block_price_size {
  height: 40px;
}
@mixin bknk_main_block_price {
  border-bottom: 1px solid #dadada;
}
@mixin bknk_main_block_tag_ul_li {
  background-color: $col-bknk-block-a;
  color: $col-bknk-block-d;
}
/*↑block　分譲地*/

/*↓new　新築*/
@mixin bknk_new {
  background-color: $col-bknk-new;
  background: linear-gradient(
    180deg,
    $col-bknk-new 0%,
    $col-bknk-new 30%,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 1) 100%
  );
}
@mixin bknk_new_close {
  background-image: url("./assets/icons/estate_info/btn_bknk_close_new.svg");
}
@mixin bknk_new_kokomado_contactbox_ul_li {
  border-color: $col-bknk-new;
}
@mixin bknk_new_kokomado_pricehistorybox_ul_li {
  border-color: $col-bknk-new;
}
@mixin bknk_main_new_h2 {
  background-image: url("./assets/icons/estate_info/bknk_tit_new.svg");
}
@mixin bknk_main_new_price {
  height: 40px;
  border-bottom: 1px solid #dadada;
}
@mixin bknk_main_new_layout {
  border-bottom: 1px solid #dadada;
}
@mixin bknk_main_new_tag_ul_li {
  background-color: $col-bknk-new-a;
  color: $col-bknk-new-d;
}
/*↑new　新築*/

/*↓used　中古*/
@mixin bknk_used {
  background-color: $col-bknk-used;
  background: linear-gradient(
    180deg,
    $col-bknk-used 0%,
    $col-bknk-used 30%,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 1) 100%
  );
}
@mixin bknk_used_close {
  background-image: url("./assets/icons/estate_info/btn_bknk_close_used.svg");
}
@mixin bknk_used_kokomado_contactbox_ul_li {
  border-color: $col-bknk-used;
}
@mixin bknk_used_kokomado_pricehistorybox_ul_li {
  border-color: $col-bknk-used;
}
@mixin bknk_main_used_h2 {
  background-image: url("./assets/icons/estate_info/bknk_tit_used.svg");
}
@mixin bknk_main_used_price {
  height: 40px;
  border-bottom: 1px solid #dadada;
}
@mixin bknk_main_used_layout {
  border-bottom: 1px solid #dadada;
}
@mixin bknk_main_used_tag_ul_li {
  background-color: $col-bknk-used-a;
  color: $col-bknk-used-d;
}
/*↑used　中古*/

/*↓apartment　マンション*/
@mixin bknk_apartment {
  background-color: $col-bknk-apartment;
  background: linear-gradient(
    180deg,
    $col-bknk-apartment 0%,
    $col-bknk-apartment 30%,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 1) 100%
  );
}
@mixin bknk_apartment_close {
  background-image: url("./assets/icons/estate_info/btn_bknk_close_apartment.svg");
}
@mixin bknk_apartment_kokomado_contactbox_ul_li {
  border-color: $col-bknk-apartment;
}
@mixin bknk_apartment_kokomado_pricehistorybox_ul_li {
  border-color: $col-bknk-apartment;
}
@mixin bknk_main_apartment_h2 {
  background-image: url("./assets/icons/estate_info/bknk_tit_apartment.svg");
}
@mixin bknk_main_apartment_about {
  margin-top: 0.5em;
}
@mixin bknk_main_apartment_about_div {
  width: calc(100% / 2) !important;
}
@mixin bknk_main_apartment_about_div_tit {
  margin-bottom: 5px;
}
@mixin bknk_main_apartment_tag {
  margin-top: 1em;
}
@mixin bknk_main_apartment_tag_ul_li {
  background-color: $col-bknk-apartment-a;
  color: $col-bknk-apartment-d;
}
@mixin bknk_main_apartment_tools {
  margin-bottom: 0.25em;
}
/*↑apartment　マンション*/

/*↓room　部屋*/
@mixin bknk_room {
  background-color: $col-bknk-apartment;
  background: linear-gradient(
    180deg,
    $col-bknk-apartment 0%,
    $col-bknk-apartment 30%,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 1) 100%
  );
}
@mixin bknk_room_close {
  background-image: url("./assets/icons/estate_info/btn_bknk_close_apartment.svg");
}
@mixin bknk_room_kokomado_contactbox_ul_li {
  border-color: $col-bknk-apartment;
}
@mixin bknk_room_kokomado_pricehistorybox_ul_li {
  border-color: $col-bknk-apartment;
}
@mixin bknk_main_room_h2 {
  background-image: url("./assets/icons/estate_info/bknk_tit_room.svg");
}
@mixin bknk_main_room_price {
  height: 40px;
  border-bottom: 1px solid #dadada;
}
@mixin bknk_main_room_layout {
  border-bottom: 1px solid #dadada;
}
@mixin bknk_main_room_tag_ul_li {
  background-color: $col-bknk-apartment-a;
  color: $col-bknk-apartment-d;
}
/*↑room　部屋*/

/*↓business　事業用*/
@mixin bknk_business {
  background-color: $col-bknk-business;
  background: linear-gradient(
    180deg,
    $col-bknk-business 0%,
    $col-bknk-business 30%,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 1) 100%
  );
}
@mixin bknk_business_close {
  background-image: url("./assets/icons/estate_info/btn_bknk_close_business.svg");
}
@mixin bknk_business_kokomado_contactbox_ul_li {
  border-color: $col-bknk-business;
}
@mixin bknk_business_kokomado_pricehistorybox_ul_li {
  border-color: $col-bknk-business;
}
@mixin bknk_main_business_h2 {
  background-image: url("./assets/icons/estate_info/bknk_tit_business.svg");
}
@mixin bknk_main_business_price {
  height: 40px;
  border-bottom: 1px solid #dadada;
}
@mixin bknk_main_business_purpose {
  border-bottom: 1px solid #dadada;
}
@mixin bknk_main_business_tag_ul_li {
  background-color: $col-bknk-business-a;
  color: $col-bknk-business-d;
}
/*↑business　事業用*/

/*▲▲▲bkn_komado▲▲▲*/

/*▼色▼*/
$col-w: rgba(255, 255, 255, 1);
$col-w-d: rgba(230, 230, 230, 1);
$col-bk: rgba(0, 0, 0, 1);
$col-hai: rgba(150, 150, 150, 1);
$col-hai-l: rgba(240, 240, 240, 1);
$col-cha: rgba(170, 160, 130, 1);
$col-cha-l: rgba(210, 200, 175, 1);
$col-tx: rgba(50, 50, 50, 1);
$col-tx-l: rgba(100, 100, 100, 1);
$col-ki: rgba(255, 240, 0, 1);
$col-ki-l: rgba(255, 240, 192, 1);
$col-ki-d: rgba(235, 220, 0, 1);
$col-ic-gro: rgba(35, 165, 35, 1);
$col-ic-blo: rgba(115, 140, 15, 1);
$col-ic-new: rgba(235, 115, 135, 1);
$col-ic-use: rgba(245, 120, 65, 1);
$col-ic-bus: rgba(170, 80, 220, 1);
$col-ic-apa: rgba(50, 150, 200, 1);
$col-ic-mix: rgba(0, 0, 0, 1);
$col-ic-sal: rgba(50, 50, 50, 1);
/*▼幅▼*/

/*▼文字▼*/
$f-tk: zen-maru-gothic, sans-serif;
$f-din: din-2014, sans-serif;
$f-dingot: din-2014, zen-maru-gothic, sans-serif;
@mixin tate-rl {
  writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  -webkit-writing-mode: vertical-rl;
  -o-writing-mode: vertical-rl;
  font-feature-settings: normal;
  -webkit-font-feature-settings: normal;
  -moz-font-feature-settings: normal;
  text-orientation: upright;
}
@mixin tate-lr {
  writing-mode: vertical-lr;
  -ms-writing-mode: tb-lr;
  -webkit-writing-mode: vertical-lr;
  -o-writing-mode: vertical-lr;
  font-feature-settings: normal;
  -webkit-font-feature-settings: normal;
  -moz-font-feature-settings: normal;
  text-orientation: upright;
}
@mixin yoko {
  writing-mode: inherit;
  -ms-writing-mode: inherit;
  -webkit-writing-mode: inherit;
  -o-writing-mode: inherit;
  font-feature-settings: "palt";
  -webkit-font-feature-settings: "palt";
  -moz-font-feature-settings: "palt";
  text-orientation: unset;
}

/*▼WAKU系▼*/

/*▼FLEX系▼*/
@mixin fx {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@mixin ilfx {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
}
@mixin fx-r {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}
@mixin fx-rre {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}
@mixin fx-c {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
@mixin fx-cre {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}
@mixin fx-w {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
@mixin fx-now {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
@mixin fx-wre {
  -ms-flex-wrap: wrap-reverse;
  flex-wrap: wrap-reverse;
}

@mixin jc-s {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
@mixin jc-e {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
@mixin jc-c {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
@mixin jc-sb {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
@mixin jc-sa {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

@mixin ai-st {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}
@mixin ai-s {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
@mixin ai-e {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}
@mixin ai-c {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@mixin ai-bl {
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
}

@mixin ac-st {
  -ms-flex-line-pack: stretch;
  align-content: stretch;
}
@mixin ac-s {
  -ms-flex-line-pack: start;
  align-content: flex-start;
}
@mixin ac-e {
  -ms-flex-line-pack: end;
  align-content: flex-end;
}
@mixin ac-c {
  -ms-flex-line-pack: center;
  align-content: center;
}
@mixin ac-sb {
  -ms-flex-line-pack: justify;
  align-content: space-between;
}
@mixin ac-sa {
  -ms-flex-line-pack: distribute;
  align-content: space-around;
}

@mixin as-a {
  -ms-flex-item-align: auto;
  align-self: auto;
}
@mixin as-s {
  -ms-flex-item-align: start;
  align-self: flex-start;
}
@mixin as-e {
  -ms-flex-item-align: end;
  align-self: flex-end;
}
@mixin as-c {
  -ms-flex-item-align: center;
  align-self: center;
}
@mixin as-b {
  -ms-flex-item-align: baseline;
  align-self: baseline;
}
@mixin as-st {
  -ms-flex-item-align: stretch;
  align-self: stretch;
}

@mixin fx-od($n: 1) {
  -webkit-box-ordinal-group: $n;
  -ms-flex-order: $n;
  -webkit-order: $n;
  order: $n;
}

/*▼グリッド系▼*/
@mixin gr {
  display: -ms-grid;
  display: grid;
}
@mixin gr-c5 {
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}
@mixin gr-c4 {
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
@mixin gr-c3 {
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}
@mixin gr-c2 {
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
}
@mixin gr-c1 {
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
}

@mixin gap($sp: 0) {
  grid-gap: $sp;
}
@mixin gap-r($sp: 0) {
  grid-row-gap: $sp;
}
@mixin gap-c($sp: 0) {
  grid-column-gap: $sp;
}

/*▼▼▼絞り込み　ウィンドウ▼▼▼*/

/*▼POPUP_MAP（窓部分）▼*/
@mixin pum {
  height: 100%;
  opacity: 0;
  transform: scale(0.85);
  transition: 0.2s;
  z-index: 2000;
}
@mixin pum_on {
  opacity: 1;
  transform: scale(1);
  top: 50px;
}
@mixin pum_scroll {
  @include fx;
  @include fx-c;
  @include jc-c;
  @include ai-c;
  width: 100%;
  height: 100%;
}
@mixin pum_tit_box {
  width: clamp(300px, 90%, 1024px);
}
@mixin pum_tit {
  z-index: 1020;
  text-align: center;
  position: relative;
}
@mixin pum_tit_span {
}
@mixin pum_tit_span_img {
  border: 5px solid black;
  border-radius: 1000px;
  height: 4em;
  background-color: white;
}
@mixin pum_box {
  z-index: 1010;
  margin-top: -2.25em;
  overflow: hidden;
  max-height: 90%;
  position: relative;
  display: inline-block;
  background-color: white;
  border-radius: 50px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.3);
  text-align: center;
  border: 5px solid black;
}
@mixin pum_box2 {
  overflow: auto;
  height: auto;
  max-height: calc(85vh - 175px);
  margin: auto;
}
@mixin pum_ovfl {
  padding: 75px 5% 35px;
  margin: auto;
  max-width: 896px;
}
@mixin pum_btn_sub_close {
  display: none;
}
@mixin rd1023_pum {
  position: fixed;
  width: 100%;
}
@mixin rd1023_pum_scroll {
  height: calc(100% - 50px);
}
@mixin rd1023_pum_tit {
  width: 100%;
}
@mixin rd1023_pum_box {
  width: 100%;
  border-radius: 0;
  border-left: none;
  border-right: none;
}
@mixin rd1023_pum_btn_sub_close {
  position: absolute;
  bottom: -12px;
  right: 16px;
  @include fx;
  @include fx-c;
  @include ai-c;
}
@mixin rd1023_pum_btn_sub_close_img_b {
  transition: 0.4s;
}
@mixin rd1023_pum_btn_sub_close_img {
  width: 2.5em;
  height: auto;
}
@mixin rd1023_pum_btn_sub_close_b {
  font-size: 0.85em;
  line-height: 1;
  opacity: 0;
  height: 0;
  overflow: visible;
}
@mixin rd1023_pum_btn_sub_close_hov_img {
  transform: rotate(180deg);
}
@mixin rd1023_pum_btn_sub_close_hov_b {
  opacity: 1;
}
@mixin rd620_pum {
  position: fixed;
  width: 100%;
  height: 100%;
  min-height: 100%;
  background-color: white;
  display: block;
  text-align: center;
  overflow-y: scroll;
}
@mixin rd620_pum_on {
  top: 0;
}
@mixin rd620_pum_scroll {
  border-radius: 50px;
  border: 5px solid $col-hai-l;
  height: auto !important;
  min-height: calc(100dvh - var(--headerHeight)) !important;
  display: block;
}
@mixin rd620_pum_scroll_scrollbar {
}
@mixin rd620_pum_tit {
}
@mixin rd620_pum_tit_span {
  background-color: $col-cha-l;
  padding: 1em;
  display: inline-block;
  width: auto;
  margin: auto;
  border-radius: 0 0 1000px 1000px;
}
@mixin rd620_pum_tit_img {
}
@mixin rd620_pum_box {
  margin-top: 0;
  border: none;
  box-shadow: none;
  max-height: unset;
  background-color: inherit;
}
@mixin rd620_pum_box2 {
  max-height: unset;
}
@mixin rd620_pum_ovfl {
  padding: 3.5em 5%;
}
@mixin rd620_pum_btn_sub_close {
  bottom: unset;
  top: 15px;
  right: 15px;
  z-index: 1020;
}

/***▼▼▼窓の中身『上段／コンテンツ部分』絞り込み用***/
@mixin pum_filter {
}
@mixin pum_filter_ctt {
  margin-bottom: 2.5em;
}
@mixin pum_filter_ctt_last {
  margin-bottom: 0;
}
@mixin pum_filter_func {
  margin-bottom: 2.5em;
  @include fx;
  @include fx-r;
  @include jc-sb;
}
@mixin pum_filter_func_last {
  margin-bottom: 0;
}
@mixin pum_filter_func_tit {
  height: 3em;
  width: 9em;
  background-color: $col-cha-l;
  border-radius: 100px 20px 20px 100px;
  @include fx;
  @include fx-c;
  @include jc-c;
  @include ai-s;
  font-weight: bold;
  padding-left: 1.5em;
}
@mixin pum_filter_func_tit_span {
}
@mixin pum_filter_func_dtl {
  width: 100%;
}
@mixin pum_filter_slider {
  // padding-right: 1em;
}
/*▼見た目だけ整えてます*/
@mixin pum_filter_slider_dtl {
  /*padding-left: 0.5em;*/
}
@mixin pum_filter_slider_setting {
  position: relative;
  height: 50px;
  width: 100%;
  padding: 0 13px;
}
@mixin pum_filter_slider_setting_bar_bg {
  width: calc(100% - 30px);
  height: 6px;
  position: absolute;
  background-color: #ededed;
  left: 0;
  top: calc(50% + 1px);
  transform: translateY(-50%);
  border-radius: 1000px;
}
@mixin pum_filter_slider_setting_bar_range {
  width: 75%;
  height: 15px;
  position: absolute;
  background-color: $col-ki;
  left: 25%;
  top: 50%;
  transform: translateY(-50%);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
}
@mixin pum_filter_slider_setting_btn {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  border-radius: 1000px;
  width: 30px;
  aspect-ratio: 1 / 1;
  border: 4px solid black;
  background-color: white;
  left: 0;
  top: calc(50% + 2px);
  transform: translateY(-50%) translateX(-50%);
  position: absolute;
}
@mixin pum_filter_slider_setting_btn_btn1 {
  left: 25%;
}
@mixin pum_filter_slider_setting_btn_btn2 {
  left: 100%;
}
@mixin pum_filter_slider_setting_fukidashi {
  font-family: $f-din;
  font-weight: 700;
  display: inline-block;
  position: relative;
  padding: 0.35em 0.5em 0.3em;
  line-height: 1;
  color: white;
  background-color: black;
  z-index: 500;
  position: absolute;
  top: -25px;
  left: 25%;
  transform: translateX(-50%);
  border-radius: 6px;
}
@mixin pum_filter_slider_setting_fukidashi_after {
  content: "";
  display: block;
  position: absolute;
  height: 1em;
  aspect-ratio: 1 / 1;
  transform: rotate(45deg);
  background-color: black;
  left: calc(1em + 1px);
  bottom: -0.5em;
  z-index: -1;
}
/*▲見た目だけ整えてます*/
@mixin pum_filter_slider_result {
  @include fx;
  @include ai-c;
  line-height: 1;
}
@mixin pum_filter_slider_result_before {
  @include as-st;
  content: "";
  display: inline-block;
  height: 1.75em;
  width: 3em;
  background-position: left center;
  background-size: contain;
  background-repeat: no-repeat;
}
@mixin pum_filter_slider_result_min_img_max {
  transition: 0.4s;
}
@mixin pum_filter_slider_result_min_max {
  font-family: $f-tk;
  font-size: 1.25em;
  font-weight: 700;
}
@mixin pum_filter_slider_result_min_max_after {
  display: inline-block;
  margin-left: 0.25em;
  font-size: 0.8em;
  font-weight: bold;
}
@mixin pum_filter_slider_result_min {
}
@mixin pum_filter_slider_result_img {
  height: 10px;
  margin: 4px 1em 0;
}
@mixin pum_filter_slider_result_max {
}
@mixin pum_filter_slider_price_result {
}
@mixin pum_filter_slider_price_result_before {
  background-image: url("./assets/icons/filter/ic_filter_price.svg");
}
@mixin pum_filter_slider_price_result_min_max {
}
@mixin pum_filter_slider_price_result_min_max_after {
  content: "万円";
}
@mixin pum_filter_slider_size_result {
}
@mixin pum_filter_slider_size_result_before {
  background-image: url("./assets/icons/filter/ic_filter_size.svg");
}
@mixin pum_filter_slider_size_result_min_max {
}
@mixin pum_filter_slider_size_result_min_max_after {
  content: "坪";
}
@mixin pum_filter_slider_age_result {
}
@mixin pum_filter_slider_age_result_before {
  background-image: url("./assets/icons/filter/ic_filter_age.svg");
}
@mixin pum_filter_slider_age_result_min_max {
}
@mixin pum_filter_slider_age_result_min_max_before {
  content: "";
  display: inline-block;
  margin-right: 0.2em;
}
@mixin pum_filter_slider_age_result_min_max_after {
  content: "";
}
@mixin pum_filter_select {
  margin-bottom: 0em;
  @include fx;
  @include fx-r;
  @include fx-w;
}
@mixin pum_filter_select_tit {
  padding-top: 0.75em;
  margin: 0.25em 0;
}
@mixin pum_filter_select_tit_span {
  font-size: 0.85em;
  font-weight: bold;
  line-height: 1;
  width: 6.5em;
  display: inline-block;
  text-align: left;
  @include fx;
  @include ai-c;
}
@mixin pum_filter_select_tit_span_bef {
  content: "";
  display: inline-block;
  height: 1.5em;
  width: 7px;
  border-radius: 1000px;
  margin-right: 6px;
  background-color: $col-cha-l;
}
@mixin pum_filter_select_dtl {
  width: calc(100% - 6em);
}
@mixin pum_filter_select_dtl_setting {
  @include fx;
  @include fx-w;
}
@mixin rd620_pum_filter_select {
}
@mixin rd620_pum_filter_select_tit {
}
@mixin rd620_pum_filter_select_tit_span {
  margin-bottom: 5px;
}
@mixin rd620_pum_filter_select_tit_span_bef {
}
@mixin rd620_pum_filter_select_dtl {
  width: 100% !important;
}

/*　▼▼▼改良版▼▼▼　*/
@mixin popup_map_filter_funcv2 {
  margin-bottom: 1em;
  padding-top: 2.5em;
}
@mixin popup_map_filter_funcv2_box {
  display: flex;
}
@mixin popup_map_filter_funcv2_ictit {
  width: 4em;
  text-align: left;
}
@mixin popup_map_filter_funcv2_ictit_img {
  width: 2.75em;
  height: auto;
}
@mixin popup_map_filter_funcv2_setting {
  padding: 0 1em;
  width: calc(100% - 4em);
}
@mixin popup_map_filter_funcv2_fkdsslider {
  display: inline-block;
  background-color: $col-ki;
  border-radius: 8px;
  padding: 0.5em 0.75em;
  line-height: 1;
}
@mixin popup_map_filter_funcv2_fkdsslider_value {
  font-size: 1em;
  font-weight: bold;
  font-family: $f-tk;
}
@mixin popup_map_filter_funcv2_fkdsslider_small {
  display: inline-block;
  margin-left: 0.25em;
  font-size: 0.875em;
  font-weight: bold;
  font-family: $f-tk;
}
@mixin popup_map_filter_funcv2_fkdsslider_small_off {
  display: none;
}
@mixin popup_map_filter_funcv2_fkdsslider_arrow {
  width: 1.5em;
  height: auto;
  margin: -4px 0.75em 0;
}
@mixin popup_map_filter_funcv2_fkdssliderhorn {
  text-align: center;
  margin-top: -1px;
  height: 12px;
  line-height: 1;
  position: relative;
  left: 0;
}
@mixin popup_map_filter_funcv2_fkdssliderhorn_img {
  height: 100%;
  width: auto;
  vertical-align: top;
  position: relative;
  left: 0;
}
/*　▲▲▲改良版▲▲▲　*/

/***▲▲▲窓の中身『絞り込み』***/

/***▼▼▼窓の中身『上段／コンテンツ部分』エリア切替用***/
@mixin popup_map_areaselect {
}
@mixin popup_map_areaselect_ctt {
  margin-bottom: 3.5em;
}
@mixin popup_map_areaselect_ctt_last {
  margin-bottom: 0;
}
@mixin popup_map_ken {
}
@mixin popup_map_dtl {
}
@mixin popup_map_dtl_other {
  margin-bottom: 1.5em;
}
@mixin popup_map_dtl_area {
}
/***▲▲▲窓の中身『上段／コンテンツ部分』エリア切替用***/

/*▼POPUP_BTNBOX（窓内下部のボタン部分）▼*/
@mixin pum_btn {
  padding: 1.5em 5% 2.5em;
  text-align: center;
}
@mixin pum_btn_btn {
  display: inline-block;
  transition: 0.4s;
  font-weight: bold;
  line-height: 1;
  margin: 0.25em;
  padding: 1em 1.5em;
  background-color: white;
  border: 5px solid $col-tx;
  border-radius: 1000px;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
}
@mixin pum_btn_btn_bef {
  content: "";
  transition: 0.4s;
  display: inline-block;
  margin-right: 0.5em;
  vertical-align: -0.25em;
  height: 1.25em;
  width: 1.25em;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
@mixin pum_btn_btn_cancel {
}
@mixin pum_btn_btn_cancel_bef {
  background-image: url("./assets/icons/ic_cancel.svg");
}
@mixin pum_btn_btn_reset {
}
@mixin pum_btn_btn_reset_bef {
  background-image: url("./assets/icons/filter/ic_reset.svg");
}
@mixin pum_btn_btn_arrow {
  background-color: $col-ki;
}
@mixin pum_btn_btn_arrow_bef {
  background-image: url("./assets/icons/ic_execution.svg");
}
@mixin pum_btn_btn_arrow_off {
  background-color: white !important;
  opacity: 1 !important;
  cursor: pointer !important;
  opacity: 1 !important;
}
@mixin pum_btn_btn_arrow_off_hov {
  background-color: $col-hai-l !important;
}
@mixin pum_btn_btn_arrow_off_hov_bef {
  transform: rotate(-360deg) scale(1.2) !important;
}
@mixin pum_btn_btn_hov {
  background-color: $col-hai-l !important;
}
@mixin pum_btn_btn_hov_bef {
  transform: rotate(-360deg) scale(1.2) !important;
}
@mixin pum_btn_btn_on {
  opacity: 1;
}
@mixin pum_btn_btn_off {
  opacity: 0.3;
  cursor: default;
}
@mixin pum_btn_btn_off_hov {
  background-color: white;
  transform: none;
}
@mixin pum_btn_btn_off_hov_bef {
  transform: none !important;
}
@mixin rd640_pum_btn {
  padding-bottom: 5em;
}
@mixin rd640_pum_btn_btn {
  width: calc(80% + 1em);
  margin-top: 0.75em;
}
@mixin rd640_pum_btn_btn_3ko {
}
@mixin rd640_pum_btn_btn_3ko_btn {
}
@mixin rd640_pum_btn_btn_3ko_btn_arrow {
  width: calc(80% + 1em);
  margin-top: 0.75em;
}
@mixin rd640_pum_btn_btn_3ko_btn_reset_cancel {
  width: 40%;
}

/***▼▼▼ON/OFFボタンデザイン01***/
@mixin btn_switch01 {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  display: inline-block;
  background-color: $col-hai-l;
  font-weight: bold;
  border-radius: 1000px;
  padding: 0.75em 1.25em;
  margin: 0.25em 0.25em 0.25em;
  line-height: 1;
  cursor: pointer;
  transition: 0.2s;
  border: 2px solid rgba(0, 0, 0, 0);
}
@mixin btn_switch01_hover {
  border: 2px solid $col-tx;
}
@mixin btn_switch01_on {
  background-color: $col-ki;
}
@mixin btn_switch01_tit {
  cursor: inherit;
  background-color: white;
  border-color: $col-tx;
}
@mixin btn_switch01_tit_on {
  background-color: white !important;
}

/***▼▼▼POPUPボタンデザイン02***/
@mixin btn_pum02 {
  display: inline-block;
  transition: 0.2s;
  opacity: 1;
  background-color: $col-cha-l;
  font-weight: bold;
  border-radius: 10px;
  padding: 0.75em 1.25em 0.85em;
  margin: 0.35em 0.15em;
  line-height: 1;
  cursor: pointer;
  border: 2px solid rgba(0, 0, 0, 0);
}
@mixin btn_pum02_on {
}
@mixin btn_pum02_on_hov {
  border: 2px solid $col-tx;
}
@mixin btn_pum02_off {
  cursor: default !important;
  opacity: 0.3;
}
@mixin btn_pum02_off_act {
  transform: none !important;
}

/***▼▼▼クリック時のアクション01***/
@mixin click_act01 {
}
@mixin click_act01_act {
  transform: translateY(2px);
  transition: 0s;
}

/***▼▼▼basic.scssにある基本設定（文字サイズレスポンシブなど）***/
@mixin twbasic01 {
  font-size: 16px;
  line-height: 1.75;
  font-feature-settings: "palt";
  -webkit-font-feature-settings: "palt";
  -moz-font-feature-settings: "palt";
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  word-wrap: break-word;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
@mixin twbasic01_all {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
@mixin twbasic01_focus {
  outline: none;
}

/*▲▲▲絞り込み　ウィンドウ▲▲▲*/
