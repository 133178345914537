/* Scss Document */

////////////////////////////////*■■■SCSS形式で作成■■■*////////////////////////////////

@import "setting";

/***学校ポイントラベル***/

/*▼ラベルデザイン*/
.school_name {
  border-style: none;
  border-width: 0px;
  position: absolute;
  background-color: transparent;
  font-size: 100%;
  opacity: 1;

  div {
    font-family: din-2014, zen-maru-gothic, sans-serif !important;

    line-height: 1;
    padding: 0.25em 0.5em;
    border-radius: 7px;
    position: absolute;
    border-color: white;
    border-width: 2px;
    border-style: solid;
    font-weight: bold;
    text-align: center;

    filter: drop-shadow(3px 3px 5px rgba(0, 0, 0, 0.2));
  }

  &.e_school {
    z-index: 300;

    div {
      background-color: rgba(255, 225, 0, 0.6);
      color: rgba(83, 71, 65, 1);
    }
  }

  &.j_school {
    z-index: 500;

    div {
      background-color: rgba(80, 200, 220, 0.6);
      color: rgba(83, 71, 65, 1);
    }
  }
}

/*---------------------------------------------------------------------------BP1920*/
@media (max-width: 1920px) {
}
/*---------------------------------------------------------------------------BP1600*/
@media (max-width: 1600px) {
}
/*---------------------------------------------------------------------------BP1280*/
@media (max-width: 1280px) {
}
/*---------------------------------------------------------------------------BP1024*/
@media (max-width: 1024px) {
}
/*---------------------------------------------------------------------------BP1023*/
@media (max-width: 1023px) {
}
/*---------------------------------------------------------------------------BP960*/
@media (max-width: 960px) {
}
/*---------------------------------------------------------------------------BP896*/
@media (max-width: 896px) {
}
/*---------------------------------------------------------------------------BP768*/
@media (max-width: 768px) {
}
/*---------------------------------------------------------------------------BP767*/
@media (max-width: 767px) {
}
/*---------------------------------------------------------------------------BP620*/
@media (max-width: 620px) {
}
/*---------------------------------------------------------------------------BP480*/
@media (max-width: 480px) {
}
