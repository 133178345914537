/* Scss Document */

////////////////////////////////*■■■SCSS形式で作成■■■*////////////////////////////////

@import "setting";

.backdrop {
  z-index: 1201;
  background-color: rgba(255, 255, 255, 0.75) !important;
  &.streetView {
    background-color: rgba(255, 255, 255, 0.2) !important;
  }
}

.bkn_komado {
  opacity: 0;
  transition: opacity 0.4s;

  .click_act01 {
    cursor: pointer;
  }

  &.off {
    opacity: 0;
  }
  &.on {
    opacity: 1;
  }

  &.not_dragging {
    transition: opacity 0.4s, transform 0.2s;
  }

  &.not_dragging.isMobile {
    transition: opacity 0.4s, transform 0.2s, left 0.2s, bottom 0.2s;
  }

  .dummy {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -100;
  }

  .room_price {
    cursor: pointer;
  }

  .swiper-no-swiping {
    cursor: text;
  }

  .swiper {
    border-radius: 30px;
    overflow: hidden;
  }
}

.bkn_komado {
  @include bknk;
  .handle {
    @include bknk_handle;
    &:hover {
      @include bknk_handle_hov;
    }
    &:active {
      @include bknk_handle_act;
    }
  }
  .handle2 {
    @include bknk_handle2;
    &:hover {
      @include bknk_handle_hov;
    }
    &:active {
      @include bknk_handle_act;
    }
  }
  .handle3 {
    @include bknk_handle3;
    &:hover {
      @include bknk_handle_hov;
    }
    &:active {
      @include bknk_handle_act;
    }
  }
  .handle4 {
    @include bknk_handle4;
    &:hover {
      @include bknk_handle_hov;
    }
    &:active {
      @include bknk_handle_act;
    }
  }
  .close {
    @include bknk_close;
    &:hover {
      @include bknk_close_hov;
    }
  }
  .pagenation {
    @include bknk_pagenation;
    .prev,
    .next {
      @include bknk_pagenation_prev_next;
    }
    .prev {
      @include bknk_pagenation_prev;
      &:hover {
        @include bknk_pagenation_prev_hov;
      }
    }
    .next {
      @include bknk_pagenation_next;
      &:hover {
        @include bknk_pagenation_next_hov;
      }
    }
    .page {
      @include bknk_pagenation_page;
    }
  }
  .main {
    h1 {
      @include bknk_main_h1;
    }
    h2 {
      @include bknk_main_h2;
    }
    article {
      @include bknk_main_article;
    }
    .price,
    .size {
      @include bknk_main_price_size;
      // > div {
      // }
      .current,
      .area {
        @include bknk_main_price_size_current_area;
        big {
          @include bknk_main_price_size_current_area_big;
        }
        small {
          @include bknk_main_price_size_current_area_small;
        }
      }
      .update,
      .unit {
        @include bknk_main_price_size_update_unit;
        > .box {
          small {
            @include bknk_main_price_size_update_unit_small;
          }
        }
        &::before {
          @include bknk_main_price_size_update_unit_bef;
        }
      }
    }
    .price {
      .update {
        &.none::before {
          @include bknk_main_price_update_none_bef;
        }
        &.down::before {
          @include bknk_main_price_update_down_bef;
        }
        &.up::before {
          @include bknk_main_price_update_up_bef;
        }
      }
    }
    .size {
      .unit::before {
        @include bknk_main_size_unit_bef;
      }
    }
    .date {
      @include bknk_main_date;
    }
    .last {
      @include bknk_main_last;
    }
    .unitprice {
      @include bknk_main_unitprice;
    }
    .tag {
      @include bknk_main_tag;
      ul {
        @include bknk_main_tag_ul;
        li {
          @include bknk_main_tag_ul_li;
        }
      }
    } /*tag*/
    .place {
      @include bknk_main_place;
      .add,
      .acc {
        @include bknk_main_place_add_acc;
      }
      // .add {
      // }
      // .acc {
      // }
    } /*place*/
    /*↓new、used、business、新築、中古、事業用要素*/
    .layout,
    .purpose {
      @include bknk_main_layout;
    }
    .about {
      @include bknk_main_about;
      > div {
        @include bknk_main_about_div;
        img {
          @include bknk_main_about_div_img;
        }
        .tit,
        .dtl,
        .dtl big,
        .dtl small {
          @include bknk_main_about_div_tit_dtl_big_small;
        }
        .tit {
          @include bknk_main_about_div_tit;
        }
        .dtl {
          big {
            @include bknk_main_about_div_dtl_big;
          }
          small {
            @include bknk_main_about_div_dtl_small;
          }
        }
      }
      // .comp {
      // }
      // .land {
      // }
      // .totalfloor {
      // }
      .yo,
      .floornum {
        .dtl {
          big {
            @include bknk_main_about_yo_dtl_big;
          }
        }
        .chiku {
          @include bknk_main_about_yo_chiku;
        }
      }
      .history {
        .dtl {
          a {
            @include bknk_main_history_dtl_a;
            &:hover {
              @include bknk_main_history_dtl_a_hov;
            }
          }
        }
      }
    }
    /*↑new、used、business、新築、中古、事業用要素*/
    /*↓apartment、room、マンション要素*/
    .name {
      @include bknk_main_name;
    }
    .roomprice {
      @include bknk_main_roomprice;
      &::-webkit-scrollbar {
        @include bknk_main_roomprice_scrollbar;
      }
      &.high {
        @include bknk_main_roomprice_high;
        dl {
          &.tit {
            dd {
              img {
                @include bknk_main_roomprice_high_dl_tit_dd_img;
              }
            }
            &:hover {
              dd {
                img {
                  @include bknk_main_roomprice_high_dl_tit_hov_dd_img;
                }
              }
            }
          }
        }
      }
      dl {
        @include bknk_main_roomprice_dl;
        &:last-child {
          @include bknk_main_roomprice_dl_last;
        }
        dt,
        dd,
        dt span,
        dd span {
          @include bknk_main_roomprice_dl_dtddspan;
        }
        dt {
          @include bknk_main_roomprice_dl_dt;
          span {
            @include bknk_main_roomprice_dl_dt_span;
          }
        }
        dd {
          @include bknk_main_roomprice_dl_dd;
          .btn_roomprice {
            @include bknk_main_roomprice_dl_dd_btnroomprice;
            .dtl1,
            .dtl2 {
              @include bknk_main_roomprice_dl_dd_btnroomprice_dtl;
            }
            .dtl1 {
              @include bknk_main_roomprice_dl_dd_btnroomprice_dtl1;
            }
            .dtl2 {
              @include bknk_main_roomprice_dl_dd_btnroomprice_dtl2;
            }
            &::after {
              @include bknk_main_roomprice_dl_dd_btnroomprice_aft;
            }
            &:hover {
              @include bknk_main_roomprice_dl_dd_btnroomprice_hov;
              &::after {
                @include bknk_main_roomprice_dl_dd_btnroomprice_hov_aft;
              }
            }
            &:first-child {
              @include bknk_main_roomprice_dl_dd_btnroomprice_first;
            }
          }
        }
        &.tit {
          @include bknk_main_roomprice_dl_tit;
          dt,
          dd,
          dt span,
          dd span {
            @include bknk_main_roomprice_dl_tit_dtddspan;
          }
          dt {
            @include bknk_main_roomprice_dl_tit_dt;
            // span {
            // }
          }
          dd {
            @include bknk_main_roomprice_dl_tit_dd;
            span {
              @include bknk_main_roomprice_dl_tit_dd_span;
            }
            img {
              @include bknk_main_roomprice_dl_tit_dd_img;
            }
          }
          &:hover {
            @include bknk_main_roomprice_dl_tit_hov;
            // dd {
            //   img {
            //   }
            // }
          }
        }
      }
    }
    /*↑apartment、room、マンション要素*/
    .tools {
      @include bknk_main_tools;
      ul {
        @include bknk_main_tools_ul;
        li {
          @include bknk_main_tools_ul_li;
          img {
            @include bknk_main_tools_ul_li_img;
          }
          &:hover {
            @include bknk_main_tools_ul_li_hov;
            img {
              @include bknk_main_tools_ul_li_hov_img;
            }
          }
        }
      }
    } /*tools*/
    .info {
      @include bknk_main_info;
      .entry,
      .view {
        @include bknk_main_info_entry_view;
        &::before {
          @include bknk_main_info_entry_view_bef;
        }
      }
      .entry {
        @include bknk_main_info_entry;
        &::before {
          @include bknk_main_info_entry_bef;
        }
      }
      .view {
        &::before {
          @include bknk_main_info_view_bef;
        }
      }
    } /*info*/
  } /*main*/
  /*↓フキダシ↓*/
  .fkds {
    @include bknk_fkds;
    .fkdsbox {
      @include bknk_fkds_fkdsbox;
      span {
        @include bknk_fkds_fkdsbox_span;
      }
    }
    &:hover {
      .fkdsbox {
        @include bknk_fkds_hov_fkdsbox;
        // span {
        // }
      }
    }
  }
  .main .tools ul li.fkds:hover .fkdsbox {
    @include bknk_main_tools_ul_li_fkds_hov_fkdsbox;
  }
  /*↑フキダシ↑*/
  /*↓子小窓↓*/
  .bkn_kokomado {
    @include bknk_bknkk;
    &.off {
      @include bknk_bknkk_off;
      > .box {
        @include bknk_bknkk_off_box;
      }
    }
    &.on {
      @include bknk_bknkk_on;
      > .box {
        @include bknk_bknkk_on_box;
      }
    }
    > .box {
      @include bknk_bknkk_box;
    }
    .scroll {
      @include bknk_bknkk_scroll;
    }
    .kokomadoclose {
      @include bknk_bknkk_kokomadoclose;
      img {
        @include bknk_bknkk_kokomadoclose_img;
      }
      &:hover {
        img {
          @include bknk_bknkk_kokomadoclose_hov_img;
        }
      }
    }
    /*↓業者開いた時↓*/
    .contactbox {
      @include bknk_bknkk_contactbox;
      &.off {
        @include bknk_bknkk_contactbox_off;
      }
      &.on {
        @include bknk_bknkk_contactbox_on;
      }
      .tit {
        @include bknk_bknkk_contactbox_tit;
        &::before {
          @include bknk_bknkk_contactbox_tit_bef;
        }
      }
      .type {
        @include bknk_bknkk_contactbox_type;
      }
      ul {
        @include bknk_bknkk_contactbox_ul;
        li {
          @include bknk_bknkk_contactbox_ul_li;
          &:last-child {
            @include bknk_bknkk_contactbox_ul_li_lastchild;
          }
          .com_name {
            @include bknk_bknkk_contactbox_ul_li_comname;
          }
          .tel {
            @include bknk_bknkk_contactbox_ul_li_tel;
            a {
              @include bknk_bknkk_contactbox_ul_li_tel_a;
              &::before {
                @include bknk_bknkk_contactbox_ul_li_tel_a_bef;
              }
              > span {
                @include bknk_bknkk_contactbox_ul_li_tel_a_span;
              }
              &:hover {
                > span {
                  @include bknk_bknkk_contactbox_ul_li_tel_a_hov_span;
                }
                &::before {
                  @include bknk_bknkk_contactbox_ul_li_tel_a_hov_bef;
                }
              }
            }
          }
        }
      }
    }
    /*↑業者開いた時↑*/

    /*↓価格履歴開いた時↓*/
    .pricehistorybox {
      @include bknk_bknkk_pricehistorybox;
      &.off {
        @include bknk_bknkk_pricehistorybox_off;
      }
      &.on {
        @include bknk_bknkk_pricehistorybox_on;
      }
      .tit {
        @include bknk_bknkk_pricehistorybox_tit;
        &::before {
          @include bknk_bknkk_pricehistorybox_tit_bef;
        }
      }
      ul {
        @include bknk_bknkk_pricehistorybox_ul;
        li {
          @include bknk_bknkk_pricehistorybox_ul_li;
          &:last-child {
            @include bknk_bknkk_pricehistorybox_ul_li_lastchild;
          }
          .ind_date {
            @include bknk_bknkk_pricehistorybox_ul_li_date;
          }
          .ind_price {
            @include bknk_bknkk_pricehistorybox_ul_li_price;
          }
        }
      }
    }
    /*↑価格履歴開いた時↑*/
  }
  /*↑子小窓↑*/
  /*↓メッセージボックス↓*/
  .messagebox {
    @include bknk_mesbox;
    &.off {
      @include bknk_mesbox_off;
      > .box {
        @include bknk_mesbox_off_box;
      }
    }
    &.on {
      @include bknk_mesbox_on;
      > .box {
        @include bknk_mesbox_on_box;
      }
    }
    > .box {
      @include bknk_mesbox_box;
      &::before {
        @include bknk_mesbox_box_bef;
      }
    }
  }
  @keyframes mesbox {
    0% {
      @include anime_mesbox1;
    }
    86% {
      @include anime_mesbox2;
    }
    100% {
      @include anime_mesbox3;
    }
  }
  @keyframes mesboxbox {
    0% {
      @include anime_mesboxbox1;
    }
    14% {
      @include anime_mesboxbox2;
    }
    86% {
      @include anime_mesboxbox3;
    }
    100% {
      @include anime_mesboxbox4;
    }
  }
  /*↑メッセージボックス↑*/
}

/****↓成約済みの場合↓****/
.bkn_komado {
  &.sold {
    @include bknk_sold;
    .close {
      @include bknk_sold_close;
    }
  }
}
/****↑成約済みの場合↑****/

/****↓土地の場合↓****/
.bkn_komado {
  &.ground {
    @include bknk_ground;
    .close {
      @include bknk_ground_close;
    }
    .bkn_kokomado .contactbox ul li {
      @include bknk_ground_kokomado_contactbox_ul_li;
    }
    .bkn_kokomado .pricehistorybox ul li {
      @include bknk_ground_kokomado_pricehistorybox_ul_li;
    }
  }
}
.bkn_komado {
  .main {
    &.ground {
      h2 {
        @include bknk_main_ground_h2;
      }
      .price,
      .size {
        @include bknk_main_ground_price_size;
      }
      .price {
        @include bknk_main_ground_price;
      }
      .tag {
        ul {
          li {
            @include bknk_main_ground_tag_ul_li;
          }
        }
      }
    }
  }
}
/****↑土地の場合↑****/

/****↓分譲地の場合↓****/
.bkn_komado {
  &.block {
    @include bknk_block;
    .close {
      @include bknk_block_close;
    }
    .bkn_kokomado .contactbox ul li {
      @include bknk_block_kokomado_contactbox_ul_li;
    }
    .bkn_kokomado .pricehistorybox ul li {
      @include bknk_block_kokomado_pricehistorybox_ul_li;
    }
  }
}
.bkn_komado {
  .main {
    &.block {
      h2 {
        @include bknk_main_block_h2;
      }
      .price,
      .size {
        @include bknk_main_block_price_size;
      }
      .price {
        @include bknk_main_block_price;
      }
      .tag {
        ul {
          li {
            @include bknk_main_block_tag_ul_li;
          }
        }
      }
    }
  }
}
/****↑分譲地の場合↑****/

/****↓新築の場合↓****/
.bkn_komado {
  &.new {
    @include bknk_new;
    .close {
      @include bknk_new_close;
    }
    .bkn_kokomado .contactbox ul li {
      @include bknk_new_kokomado_contactbox_ul_li;
    }
    .bkn_kokomado .pricehistorybox ul li {
      @include bknk_new_kokomado_pricehistorybox_ul_li;
    }
  }
}
.bkn_komado {
  .main {
    &.new {
      h2 {
        @include bknk_main_new_h2;
      }
      .price {
        @include bknk_main_new_price;
      }
      .layout {
        @include bknk_main_new_layout;
      }
      .tag {
        ul {
          li {
            @include bknk_main_new_tag_ul_li;
          }
        }
      }
    }
  }
}
/****↑新築の場合↑****/

/****↓中古の場合↓****/
.bkn_komado {
  &.used {
    @include bknk_used;
    .close {
      @include bknk_used_close;
    }
    .bkn_kokomado .contactbox ul li {
      @include bknk_used_kokomado_contactbox_ul_li;
    }
    .bkn_kokomado .pricehistorybox ul li {
      @include bknk_used_kokomado_pricehistorybox_ul_li;
    }
  }
}
.bkn_komado {
  .main {
    &.used {
      h2 {
        @include bknk_main_used_h2;
      }
      .price {
        @include bknk_main_used_price;
      }
      .layout {
        @include bknk_main_used_layout;
      }
      .tag {
        ul {
          li {
            @include bknk_main_used_tag_ul_li;
          }
        }
      }
    }
  }
}
/****↑中古の場合↑****/

/****↓マンションの場合↓****/
.bkn_komado {
  &.apartment {
    @include bknk_apartment;
    .close {
      @include bknk_apartment_close;
    }
    .bkn_kokomado .contactbox ul li {
      @include bknk_apartment_kokomado_contactbox_ul_li;
    }
    .bkn_kokomado .pricehistorybox ul li {
      @include bknk_apartment_kokomado_pricehistorybox_ul_li;
    }
  }
}
.bkn_komado {
  .main {
    &.apartment {
      h2 {
        @include bknk_main_apartment_h2;
      }
      .about {
        @include bknk_main_apartment_about;
        > div {
          @include bknk_main_apartment_about_div;
          .tit {
            @include bknk_main_apartment_about_div_tit;
          }
        }
      }
      .tag {
        @include bknk_main_apartment_tag;
        ul {
          li {
            @include bknk_main_apartment_tag_ul_li;
          }
        }
      }
      .tools {
        @include bknk_main_apartment_tools;
      }
    }
  }
}
/****↑マンションの場合↑****/

/****↓部屋の場合↓****/
.bkn_komado {
  &.room {
    @include bknk_room;
    .close {
      @include bknk_room_close;
    }
    .bkn_kokomado .contactbox ul li {
      @include bknk_room_kokomado_contactbox_ul_li;
    }
    .bkn_kokomado .pricehistorybox ul li {
      @include bknk_room_kokomado_pricehistorybox_ul_li;
    }
  }
}
.bkn_komado {
  .main {
    &.room {
      .layout {
        @include bknk_main_room_layout;
      }
      h2 {
        @include bknk_main_room_h2;
      }
      .price {
        @include bknk_main_room_price;
      }
      .tag {
        ul {
          li {
            @include bknk_main_room_tag_ul_li;
          }
        }
      }
    }
  }
}
/****↑中部屋の場合↑****/

/****↓事業用の場合↓****/
.bkn_komado {
  &.business {
    @include bknk_business;
    .close {
      @include bknk_business_close;
    }
    .bkn_kokomado .contactbox ul li {
      @include bknk_business_kokomado_contactbox_ul_li;
    }
    .bkn_kokomado .pricehistorybox ul li {
      @include bknk_business_kokomado_pricehistorybox_ul_li;
    }
  }
}
.bkn_komado {
  .main {
    &.business {
      h2 {
        @include bknk_main_business_h2;
      }
      .price {
        @include bknk_main_business_price;
      }
      .purpose {
        @include bknk_main_business_purpose;
      }
      .tag {
        ul {
          li {
            @include bknk_main_business_tag_ul_li;
          }
        }
      }
    }
  }
}
/****↑事業用の場合↑****/

/*---------------------------------------------------------------------------BP1920*/
@media (max-width: 1920px) {
}
/*---------------------------------------------------------------------------BP1600*/
@media (max-width: 1600px) {
}
/*---------------------------------------------------------------------------BP1280*/
@media (max-width: 1280px) {
}
/*---------------------------------------------------------------------------BP1024*/
@media (max-width: 1024px) {
}
/*---------------------------------------------------------------------------BP1023*/
@media (max-width: 1023px) {
}
/*---------------------------------------------------------------------------BP960*/
@media (max-width: 960px) {
}
/*---------------------------------------------------------------------------BP896*/
@media (max-width: 896px) {
}
/*---------------------------------------------------------------------------BP768*/
@media (max-width: 768px) {
}
/*---------------------------------------------------------------------------BP767*/
@media (max-width: 767px) {
}
/*---------------------------------------------------------------------------BP620*/
@media (max-width: 620px) {
}
/*---------------------------------------------------------------------------BP480*/
@media (max-width: 480px) {
}
