$borderStyle: 1px solid #aaa082;
$rowHeight: 4.6%;
$contentGap: 1.5%;

@mixin thBase {
  height: 100%;

  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  border-bottom: $borderStyle;
  border-right: $borderStyle;
}

@mixin tdBase {
  height: 100%;

  border-bottom: $borderStyle;
  display: flex;
  align-items: center;
  padding-left: 0.5em;
}

@mixin trBase {
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 20% 80%;
  height: $rowHeight;
}

@mixin trBaseDiv {
  div:nth-of-type(odd) {
    @include thBase;
  }

  div:nth-of-type(even) {
    @include tdBase;
  }
}

.contentWrapper {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  overflow: hidden;

  border: $borderStyle;

  font-size: 84%;

  .name {
    box-sizing: border-box;
    height: 6%;
    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 1.2em;

    border-bottom: $borderStyle;
  }

  .price {
    @include trBase;
    height: calc($rowHeight * 2);
    @include trBaseDiv;

    div:nth-of-type(2) {
      font-size: 1.6em;
    }

    &.ground {
      grid-template-columns: 20% 30% 20% 30%;

      div:nth-of-type(2) {
        border-right: $borderStyle;
      }
    }
  }

  .contentBasic {
    @include trBase;
    height: calc($rowHeight * 4);
    margin-top: $contentGap;
    border-top: $borderStyle;
    @include trBaseDiv;
  }

  .area {
    @include trBase;
    @include trBaseDiv;
  }

  .road {
    @include trBase;
    height: calc($rowHeight * 2);
    @include trBaseDiv;
  }

  .conditions {
    @include trBase;
    @include trBaseDiv;

    &.ground {
      height: calc($rowHeight * 2);

      &.additional {
        margin-top: $contentGap;
        border-top: $borderStyle;
      }
    }

    &.house {
      grid-template-columns: 20% 30% 20% 30%;

      div:nth-of-type(2) {
        border-right: $borderStyle;
      }
    }

    &.condominium {
      height: calc($rowHeight * 5);
    }
  }

  .totalArea {
    @include trBase;
    margin-top: $contentGap;
    border-top: $borderStyle;
    grid-template-columns: 20% 30% 20% 30%;
    @include trBaseDiv;

    div:nth-of-type(2) {
      border-right: $borderStyle;
    }
  }

  .structure {
    @include trBase;
    @include trBaseDiv;

    &.condominium {
      grid-template-columns: 20% 30% 20% 30%;

      div:nth-of-type(2) {
        border-right: $borderStyle;
      }
    }
  }

  .layoutCompletion {
    @include trBase;
    height: calc($rowHeight * 2);
    @include trBaseDiv;
  }

  .completion {
    @include trBase;
    @include trBaseDiv;
  }

  .geoInfo {
    @include trBase;
    height: calc($rowHeight * 3);
    margin-top: $contentGap;
    border-top: $borderStyle;
    @include trBaseDiv;
  }

  .innerInfo {
    @include trBase;
    margin-top: $contentGap;
    border-top: $borderStyle;
    @include trBaseDiv;
  }

  .memo {
    @include trBase;
    @include trBaseDiv;

    &.ground {
      height: calc($rowHeight * 2.65);
    }

    &.house {
      height: calc($rowHeight * 1.65);
    }

    &.condominium {
      height: calc($rowHeight * 1.85);
    }

    &.business {
      height: calc($rowHeight * 2.65);
    }
  }
}

.mapContent {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 10px;

  border: $borderStyle;
  border-left: none;

  .mapWrapper {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;

    img {
      height: 99.8%;
    }

    &.noMap {
      animation-name: fade-in;
      animation-duration: 3s;
      animation-timing-function: ease-in;
    }
  }

  .qrCode {
    position: absolute;
    right: 1em;
    bottom: 1em;
  }
}

.companyContent {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 10px;

  border: $borderStyle;
  border-top: none;
  border-radius: none;

  display: grid;
  grid-template-columns: calc((100% - 9em) / 2) calc((100% - 9em) / 2) 9em;

  & > * {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: left;

    height: 100%;
    padding-right: 0.5em;

    &:nth-child(2) {
      display: inline-block;
    }
  }

  .name {
    margin: 0 2em 0 0.5em;
  }

  .name,
  .address {
    transition: opacity 0.2s;

    &.visible {
      opacity: 1;
    }

    &.hidden {
      opacity: 0;
    }
  }

  .fax {
    margin-left: 1em;
  }

  .logo {
    display: inline-flex;
    align-items: flex-end;
    justify-content: flex-end;

    .switch {
      display: flex;
      flex-wrap: wrap;
      width: 2em;
      height: 100%;
      align-items: center;
      justify-content: center;

      .up,
      .down {
        cursor: pointer;
        transition: all 0.3s;

        &.disabled {
          cursor: not-allowed;
          opacity: 0.2;
        }

        &:hover {
          transform: scale(1.3);
        }
      }
    }

    img {
      width: 6em;
    }
  }
}

@media screen and (orientation: portrait) {
  .contentWrapper {
    border-bottom: none;

    .memo {
      height: $rowHeight !important;
    }
  }

  .companyContent {
    font-size: 90%;
    display: block;

    .address {
      padding-left: 1em;
    }

    .logo {
      display: flex;
      margin-top: -2em;

      .switch {
        display: none;
      }
    }
  }
}

@media screen and (orientation: portrait) and (min-aspect-ratio: 1/2) and (max-aspect-ratio: 3/5) {
  .contentWrapper {
    height: 100dvw;
  }
}

@media screen and (orientation: portrait) and (min-aspect-ratio: 3/5) and (max-aspect-ratio: 1/1) {
  .contentWrapper {
    height: 94dvw;
  }
}

@media (max-width: 480px) {
  .contentWrapper {
    font-size: 10px;
  }
}

@media print {
  .companyContent {
    .logo {
      .switch {
        display: none;
      }
    }
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  80% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
