@import "setting";

.estateTypeList {
  padding: 2em;
}

.container {
  border-radius: 10px;
  border: 2px solid black;
  box-shadow: none;
}

.ground,
.house,
.used,
.condominium,
.business {
  min-width: 80em;

  tr {
    th {
      background-color: $col-cha-l;
      font-weight: 700;
    }
  }
}

.ground,
.house,
.used,
.business {
  tr {
    th:nth-of-type(2),
    th:nth-of-type(3),
    th:nth-of-type(4),
    td:nth-of-type(2),
    td:nth-of-type(3),
    td:nth-of-type(4) {
      text-align: right;
    }

    th:nth-of-type(1) {
      width: 25%;
    }

    th:nth-of-type(2) {
      width: 8%;
    }

    th:nth-of-type(3) {
      width: 8%;
    }

    th:nth-of-type(4) {
      width: 8%;
    }

    th:nth-of-type(5) {
      width: 21%;
    }

    th:nth-of-type(6) {
      width: 30%;
    }

    .lotLabel {
      background-color: $col-cha-l;
      font-weight: 700;
      color: white;
    }

    .currentStatusLabel {
      background-color: #eee;
      font-weight: 700;
      color: black;
    }
  }
}

.condominium {
  tr {
    th:nth-of-type(2),
    th:nth-of-type(3),
    th:nth-of-type(4),
    td:nth-of-type(2),
    td:nth-of-type(3),
    td:nth-of-type(4) {
      text-align: right;
    }

    th:nth-of-type(1) {
      width: 25%;
    }

    th:nth-of-type(2) {
      width: 8%;
    }

    th:nth-of-type(3) {
      width: 8%;
    }

    th:nth-of-type(4) {
      width: 8%;
    }

    th:nth-of-type(5) {
      width: 21%;
    }

    th:nth-of-type(6) {
      width: 30%;
    }
  }
}

.noResult {
  padding-top: 3em;
  text-align: center;
  font-weight: 700;
}

@media print {
  @page {
    size: A4 portrait;
    margin: 0mm 5mm;
  }

  .headerBar {
    display: none;
  }

  // .estateTypeList {
  //   div {
  //     page-break-after: always;
  //   }
  // }

  .ground,
  .house,
  .used,
  .condominium,
  .business {
    min-width: 100%;

    * {
      font-size: 10px !important;
    }

    tr {
      th {
        background-color: $col-cha-l;
        font-weight: 700;
        padding: 0.25em;

        &:nth-of-type(1) {
          width: 30%;
        }

        &:nth-of-type(2) {
          width: 8%;
        }

        &:nth-of-type(3) {
          width: 8%;
        }

        &:nth-of-type(4) {
          width: 13%;
        }

        &:nth-of-type(5) {
          width: 21%;
        }

        &:nth-of-type(6) {
          width: 20%;
        }
      }

      td {
        padding: 0.25em;
      }
    }

    .currentStatusLabel,
    .lotLabel {
      background-color: transparent !important;
      color: black !important;
      border: none !important;
      padding: 0 0 0 0.5em !important;
      margin: 0 !important;
      height: unset !important;

      span {
        line-height: 1 !important;
        font-weight: normal !important;
        padding: 0 !important;
        margin: 0 !important;
      }
    }
  }
}
