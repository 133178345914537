@import "setting";

.backdrop {
  background-color: rgba(255, 255, 255, 0.75) !important;
}

.dialogPaper {
  position: relative;
  border-radius: 40px !important;
  border: solid 4px black;
  padding: 2em 0 1em 0;
  overflow-y: visible !important;

  .actions {
    display: flex;
    justify-content: center;
    margin-top: 1em;

    .okButton {
      border-radius: 40px;
      border: solid 4px black;
      background-color: $col-ki;
      color: black;
      font-weight: 700;
      width: 120px;

      &:hover {
        background-color: $col-ki-d;
      }
    }
  }
}
